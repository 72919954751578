import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSurveys } from "API/panelAPI";

export type AdminSurveyType = {
  Id: number;
  email: string;
  city: string;
  creation_date: string;
  date_of_birth: string;
  education: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  phonenumber: string;
  reason_dismissal: string;
  resume_path: string;
  role_id: number;
  year_work_experience: string;
};

type QuestionType = {
  question: string;
  file: boolean;
  id: number;
};

type VideoQuestionType = {
  question: string;
  questionDesc: string;
  id: number;
};

let initialState = {
  surveys: [] as AdminSurveyType[],
  actualSurvey: {} as AdminSurveyType,
  surveyQuestions: [
    { question: "Ваше имя", id: 0, file: false },
    { question: "Ваша фамилия", id: 1, file: false },
    { question: "Ваше отчество", id: 2, file: false },
  ] as QuestionType[],
  videoQuestions: [
    {
      question: "Что Вы можете рассказать о себе?",
      questionDesc:
        "Что может включать в себя такой рассказ: с какой целью Вы ищете работу, что Вы умеете, где Вы обучались и работали, почему Вас заинтересовала данная вакансия, почему Вы считаете, что подходите для работы в компании.",
      id: 0,
    },
    {
      question: "Что Вы можете рассказать?",
      questionDesc:
        "Что может включать в себя такой рассказ: с какой целью Вы ищете работу, что Вы умеете, где Вы обучались и работали, почему Вас заинтересовала данная вакансия, почему Вы считаете, что подходите для работы в компании.",
      id: 1,
    },
  ] as VideoQuestionType[],
  psychoQuestions: [
    {
      question:
        "Ваши логические умозаключения чаще всего обоснованны и правильны?",
      answers: [
        "да",
        "скорее 'да'",
        "затрудняюсь ответить",
        "скорее нет",
        "нет",
      ],
      id: 0,
    },

    {
      question: "Ваши умозаключения чаще всего обоснованны?",
      answers: ["да", "скорее нет", "нет"],
      id: 1,
    },
  ],
};

type InitialState = typeof initialState;
interface ChangePositionPayload {
  id: string;
  position: number;
}

export const getSurveysThunk = createAsyncThunk(
  "admin/getSurveysThunk",
  async (_, { dispatch }) => {
    getSurveys().then((res) => {
      if (res.status === 200) {
        dispatch(saveSurveys(res.data));
      }
    });
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    saveSurveys(state, action: PayloadAction<AdminSurveyType[]>) {
      state.surveys = action.payload;
    },
    getActualSurvey(state, action: PayloadAction<string>) {
      const surveyId = parseInt(action.payload);
      const foundSurvey = state.surveys.find((s) => s.Id === surveyId);
      if (foundSurvey) {
        state.actualSurvey = foundSurvey;
      }
    },
    changePosition(state, action: PayloadAction<ChangePositionPayload>) {
      const surveyId = parseInt(action.payload.id);
      state.surveys.map((s) => {
        if (s.Id === surveyId) {
          s.role_id = action.payload.position;
        }
        return s;
      });
    },
    changeSurveyQuestions(state, action: PayloadAction<QuestionType[]>) {
      state.surveyQuestions = action.payload;
    },
    changeVideoQuestions(state, action: PayloadAction<VideoQuestionType[]>) {
      state.videoQuestions = action.payload;
    },
    changePsychoQuestions(
      state,
      action: PayloadAction<typeof state.psychoQuestions>
    ) {
      state.psychoQuestions = action.payload;
    },
  },
});

export const {
  saveSurveys,
  getActualSurvey,
  changePosition,
  changeSurveyQuestions,
  changeVideoQuestions,
  changePsychoQuestions,
} = adminSlice.actions;
export default adminSlice.reducer;
