import {FC} from 'react';
import './JobTitlePage.scss';
import {IJob, JobTitleTable} from "../../components/JobTitleTable/JobTitleTable";
import {Pagination} from "../../components/Pagination/Pagination";


export interface IJobTitlesPageProps {

}

const items: IJob[] = [
    {
        id: '1',
        name: "Дизайнер",
        link: "https://skill-radar.ru/sign-in&path=Программист_GO"
    },
    {
        id: '2',
        name: "Frontend-разработчик",
        link: "https://skill-radar.ru/sign-in&path=Программист_GO"
    },
    {
        id: '3',
        name: "Backend-разработчик",
        link: "https://skill-radar.ru/sign-in&path=Программист_GO"
    },
    {
        id: '4',
        name: "Frontend-разработчик",
        link: "https://skill-radar.ru/sign-in&path=Программист_GO"
    },
    {
        id: '5',
        name: "Бухгалтер",
        link: "https://skill-radar.ru/sign-in&path=Программист_GO"
    },
    {
        id: '6',
        name: "Менеджер",
        link: "https://skill-radar.ru/sign-in&path=Программист_GO"
    },
    {
        id: '7',
        name: "Создатель контента",
        link: "https://skill-radar.ru/sign-in&path=Программист_GO"
    },
    {
        id: '8',
        name: "Копирайтер",
        link: "https://skill-radar.ru/sign-in&path=Программист_GO"
    }
]

export const JobTitlesPage: FC<IJobTitlesPageProps> = () => {
    return (<main className={'job-titles-page'}>
        <header className={'job-titles-page-header'}>
            <h1 className="job-titles-page-title">
                Должности 1-10 из 234
            </h1>
            <Pagination/>
        </header>
        <JobTitleTable items={items}/>
    </main>)
}