import {FC} from 'react';
import './AddEmployeePage.scss';
import {Button} from "../../components/Button/Button";
import {Input} from "../../components/Input/Input";

export interface IAddEmployeePageProps {

}

export const AddEmployeePage: FC<IAddEmployeePageProps> = () => {
    return (
        <main className={'add-employee-page'}>
            <h1 className={'add-employee-page-title'}>Добавить сотрудника</h1>
            <div className="add-employee-page-content">
                <Input label={'Введите Фамилию сотрудника'} labelClassName={'add-employee-page-input-label'} inputWrapperClassName={'add-employee-page-input'}/>
                <Input label={'Введите Имя сотрудника'} labelClassName={'add-employee-page-input-label'} inputWrapperClassName={'add-employee-page-input'}/>
                <Input label={'Введите Отчество сотрудника'} labelClassName={'add-employee-page-input-label'} inputWrapperClassName={'add-employee-page-input'}/>
                <Input label={'Введите номер телефона'} labelClassName={'add-employee-page-input-label'} inputWrapperClassName={'add-employee-page-input'}/>
            </div>
            <div className="add-employee-page-buttons">
                <Button variant={'purple'}>Добавить</Button>
                <Button variant={'flat'}>Отменить</Button>
            </div>
        </main>
    );
};
