import { useNavigate } from 'react-router-dom';
import './MentorCard.scss';


export interface Mentor {
    id: string | number,
    name: string,
    experience: number,
    position: string
}

interface MentorCardProps {
    className?: string,
    mentor: Mentor
}

export const MentorCard = ({className = '', mentor}: MentorCardProps) => {

    const navigate = useNavigate()
    return (
        <div className="mentor-card">
            <img src="/mentor-placeholder.png" alt={mentor.name} className="mentor-card__image"/>
            <div className="mentor-card-body">
                <p className="mentor-card__name">{mentor.name}</p>
                <p className="mentor-card__info">Срок работы: <span>{mentor.experience} лет</span></p>
                <p className="mentor-card__info">Должность: <span>{mentor.position}</span></p>
            </div>
            {/* ВРЕМЕННОЕ РЕШЕНИЕ */}
            <button className="mentor-card__button" onClick={() => {navigate('/mentor')}}>
                Подробнее
            </button>
        </div>
    );
};