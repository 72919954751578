import { useSidebarDesperate } from '../hooks/mainHooks';
import './ChatPage.scss';

export const ChatPage = () => {
    useSidebarDesperate(true)
    return (
        <main className='chat-page'>
            <div className="chat-page-header">
                <a href={'/chats'} className='chat-page__back-btn'>
                    <svg width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.266602 12.9998L12.7874 25.5207L15.7333 22.5748L6.14994 12.9915L15.7333 3.40817L12.7874 0.479004L0.266602 12.9998Z"
                            fill="#F7F7FC" />
                    </svg>
                </a>
                <h1 className='chat-page__title'>Наставник</h1>
            </div>
            <div className="chat-page-body">
                <div className="chat-page-message">
                    <div className="chat-page-message-resend">
                        <span className="chat-page-message-resend-author">Вы</span>
                        <span className="chat-page-message-resend-content">Что делать дальше?</span>
                    </div>
                    <span className="chat-page-message-content">Напиши план и скинь мне на согласование</span>
                    <span className="chat-page-message-date">09.13</span>
                </div>
                <div className="chat-page-message mine">
                    <span className="chat-page-message-content">Понял, принял, обработал. Пошел выполнять)</span>
                    <span className="chat-page-message-date">09.13 Прочитано</span>
                </div>
                <div className='divider'><span className="divider-content">Ср, 17/10</span></div>
                <div className="chat-page-message mine">
                    <div className="chat-page-message-resend">
                        Голосовое сообщение
                    </div>
                    <span className="chat-page-message-date">09.13 Прочитано</span>
                </div>
                <div className="chat-page-message">
                    <div className="chat-page-message-content">Все правки написал в комментариях. Исправляй)</div>
                    <span className="chat-page-message-date">09.13 Прочитано</span>
                </div>
            </div>

            <div className="chat-page-sender">
                <button className="chat-page-sender-plus">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.0832 17.0832V29.5832H12.9165V17.0832H0.416504V12.9165H12.9165V0.416504H17.0832V12.9165H29.5832V17.0832H17.0832Z"
                            fill="#ADB5BD" />
                    </svg>
                </button>
                <input value='Будет сделано, капитан' placeholder={"Введите текст сообщения"}
                    className='chat-page-sender__input' type="text" name="" id="" />
                <button className="chat-page-sender__button">
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M37.2964 0.703101C37.0856 0.493215 36.8192 0.347898 36.5285 0.284236C36.2379 0.220574 35.9351 0.241212 35.6558 0.343726L1.28082 12.8437C0.984368 12.9562 0.729136 13.1561 0.549029 13.4171C0.368921 13.678 0.272461 13.9876 0.272461 14.3047C0.272461 14.6217 0.368921 14.9313 0.549029 15.1922C0.729136 15.4532 0.984368 15.6532 1.28082 15.7656L14.7027 21.125L24.609 11.1875L26.8121 13.3906L16.859 23.3437L22.2339 36.7656C22.3497 37.0563 22.5502 37.3056 22.8092 37.4811C23.0683 37.6566 23.3741 37.7503 23.6871 37.75C24.0028 37.7435 24.3092 37.6415 24.5658 37.4574C24.8225 37.2733 25.0173 37.0158 25.1246 36.7187L37.6246 2.34373C37.731 2.06728 37.7567 1.76627 37.6986 1.47579C37.6405 1.18531 37.501 0.917335 37.2964 0.703101Z"
                            fill="white" />
                    </svg>
                </button>
            </div>
        </main>
    );
};