import {FC, SVGProps} from 'react';

export const CopyIcon: FC<SVGProps<SVGSVGElement>> = ({...restProps}) => {
    return (
        <svg className={'copy-icon'} width="20" height="20" viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.66683 13.3335H5.00016C4.55814 13.3335 4.13421 13.1579 3.82165 12.8453C3.50909 12.5328 3.3335 12.1089 3.3335 11.6668V5.00016C3.3335 4.55814 3.50909 4.13421 3.82165 3.82165C4.13421 3.50909 4.55814 3.3335 5.00016 3.3335H11.6668C12.1089 3.3335 12.5328 3.50909 12.8453 3.82165C13.1579 4.13421 13.3335 4.55814 13.3335 5.00016V6.66683M8.3335 16.6668H15.0002C15.4422 16.6668 15.8661 16.4912 16.1787 16.1787C16.4912 15.8661 16.6668 15.4422 16.6668 15.0002V8.3335C16.6668 7.89147 16.4912 7.46755 16.1787 7.15499C15.8661 6.84242 15.4422 6.66683 15.0002 6.66683H8.3335C7.89147 6.66683 7.46755 6.84242 7.15499 7.15499C6.84242 7.46755 6.66683 7.89147 6.66683 8.3335V15.0002C6.66683 15.4422 6.84242 15.8661 7.15499 16.1787C7.46755 16.4912 7.89147 16.6668 8.3335 16.6668Z"
                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
