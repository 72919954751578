import './MetaTest.scss'
import React, { useEffect, useState } from "react";
import { animate, motion } from "framer-motion";
import { Breadcrumb } from "../../components/Breadcrumbs/Breadcrumb";
import { Button } from "../../components/Button/Button";
import useRedirect, { useLogCheck } from 'routes/hooks/mainHooks';

const images = [
    "/meta-1.png",
    "/meta-2.png",
    "/meta-3.png",
    "/meta-4.png",
    "/meta-5.png",
    "/meta-6.png",
    "/meta-7.png"
];

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
        }
    }
}

const item = {
    hidden: { y: -20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
    }
}
export const MetaTest = () => {
    const [selected, setSelected] = useState<number | null>(null);
    useLogCheck()
    return (
        <main className="meta-test">
            <h1 className='meta-test__title'>Метафорические карты</h1>
            <small className='meta-test__small'>
                Взгляните на портреты людей. Выберите 1 портрет из предложенных, внимательно изучите в течение 5-7 секунд
                и ответьте на вопросы.
            </small>
            <motion.div
                className="meta-test-images"
                variants={container}
                initial="hidden"
                animate="visible"
            >
                {
                    images.map((image, index) => {
                        return <motion.img variants={item} onClick={() => setSelected((index))}
                            className={`meta-test-images__image ${index === selected ? 'active' : ''}`}
                            src={image} alt="Meta test image" />
                    })
                }
            </motion.div>

            <a className={`meta-test__link ${selected !== null ? 'visible' : ''}`} href="/meta-test-answer"><Button className={`meta-test__button`}>Далее</Button></a>
        </main>
    );
}
    ;