import React, { SyntheticEvent, useState } from 'react';
import './Login.scss';
import { sendCodeAPI, sendNumberAPI, updatePassword } from '../../API/loginAPI';
import { useNavigate } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import useRedirect, { useNoAuthCheck } from '../hooks/mainHooks';
import { useAppSelector } from '../../redux/reduxHooks';

const Login = () => {

    const phoneNumber = useAppSelector(state => state.authReducer.phoneNumber)
    const [code, setCode] = useState<string | null>(null)
    const [newPassSent, setNewPass] = useState<boolean>(false)
    const [codeSent, setCodeSent] = useState(false);
    const navigate = useNavigate()
   /*  useRedirect((!!localStorage.getItem('token')), '/entersuccess') */
    /*  useNoAuthCheck() */
     useRedirect(phoneNumber === '', '/sign-in')


    const handleChangerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!codeSent)
            setCode(event.target.value)
    }

    const sendCode = (e: SyntheticEvent) => {
        e.preventDefault();
        if (code && phoneNumber) {
            sendCodeAPI(phoneNumber, code).then(res => {
                if (res.status === 200) {
                    localStorage.setItem('token', res.data.jwt)
                    setCodeSent(true)
                    navigate('/entersuccess')
                }
            }).catch(err => console.error(err))
        }
    }

    return (
        <main className={'login'}>
            <form onSubmit={sendCode} className="login-form">
                <h1 className="login-form__title">Вход в систему</h1>
                <small>Вы уже зарегистрированы. <br />Ранее на номер +7 (___) ___-__-__ был отправлен пароль. </small>
                <label htmlFor={'phone'} className="login-form-input-block">
                    <span className='login-form__label'>Введите пароль из SMS</span>
                    <ReactInputMask onChange={handleChangerCode} mask={'999999'}
                        className="sign-in-form--alt-code-input-block__input" placeholder='Пароль'
                        type="text" />
                </label>
                <button onClick={sendCode} className="login-form__button">Войти</button>
                <a className='login-form--alt__change' href="#" onClick={() => {
                    if (phoneNumber) {
                        updatePassword(phoneNumber).then(res => {
                            console.log(res);
                            setNewPass(true)
                        })
                    }
                }}>{newPassSent ? 'Пароль отправлен. Отправить снова?' : 'Забыли пароль?'}

                </a>
            </form>
        </main>
    );
};

export default Login;