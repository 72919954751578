import { ReactNode } from "react";
import './Breadcrumbs.scss';

type BreadcrumbProps = {
    route: string
    currentRoute: string
    children: React.ReactNode
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ route, currentRoute, children }) => {
    const isActive = currentRoute === route;

    return (
        <p className={`breadcrumb ${isActive ? 'active' : ''}`}>
            {children}
        </p>
    );
};