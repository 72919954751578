import {FC} from 'react';
import {ChevronIcon} from "../../assets/icons/ChevronIcon";
import './Pagination.scss';
export interface IPaginationProps {

}

const countOfPages = 4;
export const Pagination: FC<IPaginationProps> = () => {
    return (
        <div className={'pagination'}>
            <button disabled={true} className={'pagination-button'}>
                <ChevronIcon direction={'left'}/>
            </button>
            {Array(countOfPages).fill(null).map((_, index) => {
                return <button className={'pagination-button'}>
                    {index + 1}
                </button>
            })}
            <button className={'pagination-button'}>
                ...
            </button>
            <button className={'pagination-button'}>
                20
            </button>
            <button className={'pagination-button'}>
                <ChevronIcon direction={'right'}/>
            </button>
        </div>
    );
};
