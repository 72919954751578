import { AnswerType } from "redux/psychoReducer"
import { instance } from "./main"
import { ResultType } from "routes/Test/Test"

export const getTestQuestionsAPI = () => 
{
    return instance.get('/interview/test')
}

export const sendTestQuestionsAPI = (result: ResultType[]) => 
    {
        return instance.post('/interview/test', result)
    }