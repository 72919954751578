import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/reduxHooks';
import { saveMentor } from '../../redux/roadmapReducer';
import './MentorPage.scss'

export const MentorPage = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    return (
        <main className={'mentor-page'}>
            <h1 className="mentor-page__title">
                Илона Бекоева
            </h1>
            <div className="mentor-page-benefits">
                <div className="mentor-page-benefit">
                    <svg className="mentor-page-benefit__icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6L12.75 11.25L18 11.25C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75L12 12.75C11.5858 12.75 11.25 12.4142 11.25 12L11.25 6C11.25 5.58579 11.5858 5.25 12 5.25Z"
                            fill="#731975" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
                            fill="#731975" />
                    </svg>
                    <span className="mentor-page-benefit__text">
                        Круглосуточно на связи
                    </span>
                </div>
                <div className="mentor-page-benefit">
                    <svg className="mentor-page-benefit__icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M3.75 3.75V20.25H20.25V3.75H3.75ZM2.25 3.6C2.25 2.85441 2.85442 2.25 3.6 2.25H20.4C21.1456 2.25 21.75 2.85442 21.75 3.6V20.4C21.75 21.1456 21.1456 21.75 20.4 21.75H3.6C2.85441 21.75 2.25 21.1456 2.25 20.4V3.6Z"
                            fill="#731975" />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M12 4.75C12.4143 4.75 12.75 5.08579 12.75 5.5V6.63088C13.7987 6.80659 14.848 7.28725 15.5304 7.96967C15.8233 8.26256 15.8233 8.73744 15.5304 9.03033C15.2375 9.32322 14.7626 9.32322 14.4697 9.03033C13.9415 8.50209 12.9287 8.08398 11.9799 8.05845C11.424 8.04349 10.9874 8.16616 10.7082 8.37765C10.4603 8.5654 10.25 8.8865 10.25 9.50001C10.25 9.75463 10.3066 9.92373 10.3804 10.0472C10.4567 10.1751 10.5768 10.2962 10.7621 10.4141C11.1625 10.669 11.7281 10.8303 12.4474 11.0264C12.4662 11.0316 12.4852 11.0367 12.5043 11.0419C13.1495 11.2177 13.9307 11.4305 14.5433 11.8204C14.8737 12.0306 15.1833 12.3079 15.4077 12.684C15.6348 13.0644 15.75 13.5046 15.75 14C15.75 15.1018 15.2634 15.942 14.5063 16.4722C13.9916 16.8327 13.3778 17.0337 12.75 17.109V18.5C12.75 18.9142 12.4143 19.25 12 19.25C11.5858 19.25 11.25 18.9142 11.25 18.5V17.0647C10.1574 16.875 9.0696 16.3427 8.40004 15.45C8.15152 15.1186 8.21867 14.6485 8.55004 14.4C8.88142 14.1515 9.35152 14.2186 9.60004 14.55C10.071 15.1779 11.0252 15.6055 12.027 15.6415C12.681 15.665 13.2568 15.516 13.6459 15.2435C14.0048 14.9922 14.25 14.6092 14.25 14C14.25 13.7454 14.1934 13.5763 14.1197 13.4528C14.0434 13.3249 13.9233 13.2038 13.738 13.0859C13.3376 12.831 12.772 12.6697 12.0527 12.4736C12.0338 12.4684 12.0149 12.4633 11.9958 12.4581C11.3506 12.2823 10.5694 12.0695 9.95676 11.6796C9.6264 11.4694 9.31684 11.1921 9.09237 10.816C8.86532 10.4357 8.75004 9.99538 8.75004 9.50001C8.75004 8.48349 9.13019 7.69119 9.80253 7.18193C10.2303 6.85793 10.7349 6.6798 11.25 6.60324V5.5C11.25 5.08579 11.5858 4.75 12 4.75Z"
                            fill="#731975" />
                    </svg>
                    <span className="mentor-page-benefit__text">
                        Плюс 2
                    </span>
                </div>

                <div className="mentor-page-benefit">
                    <svg className="mentor-page-benefit__icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M8.19135 5.06177C8.9304 4.03532 10.1556 3.25 12 3.25C13.8444 3.25 15.0696 4.03532 15.8087 5.06177C16.5202 6.04998 16.75 7.21332 16.75 8V11.25H17.4C18.1456 11.25 18.75 11.8544 18.75 12.6V19.4C18.75 20.1456 18.1456 20.75 17.4 20.75H6.6C5.85442 20.75 5.25 20.1456 5.25 19.4V12.6C5.25 11.8544 5.85442 11.25 6.6 11.25H7.25V8C7.25 7.21332 7.47984 6.04998 8.19135 5.06177ZM8.75 11.25H15.25V8C15.25 7.45335 15.0798 6.61668 14.5913 5.93823C14.1304 5.29802 13.3556 4.75 12 4.75C10.6444 4.75 9.8696 5.29802 9.40865 5.93823C8.92016 6.61668 8.75 7.45335 8.75 8V11.25ZM6.75 12.75V19.25H17.25V12.75H6.75Z"
                            fill="#731975" />
                    </svg>
                    <span className="mentor-page-benefit__text">
                        Плюс 2
                    </span>
                </div>

                <div className="mentor-page-benefit">
                    <svg className="mentor-page-benefit__icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M4.33167 14.3404C4.45967 14.5918 4.71791 14.7501 5 14.7501H10.25V21.0001C10.25 21.3249 10.4591 21.6128 10.7681 21.7133C11.077 21.8137 11.4155 21.7039 11.6066 21.4412L19.6066 10.4412C19.7725 10.213 19.7963 9.9111 19.6683 9.65972C19.5403 9.40834 19.2821 9.25005 19 9.25005H13.75V3.00005C13.75 2.6752 13.5409 2.38727 13.2319 2.28681C12.923 2.18635 12.5845 2.2962 12.3935 2.55892L4.39345 13.5589C4.22753 13.7871 4.20366 14.089 4.33167 14.3404ZM12.25 5.30644V10.0001C12.25 10.4143 12.5858 10.7501 13 10.7501H17.5272L11.75 18.6937V14.0001C11.75 13.5858 11.4142 13.2501 11 13.2501H6.47283L12.25 5.30644Z"
                            fill="#731975" />
                    </svg>
                    <span className="mentor-page-benefit__text">
                        Плюс 2
                    </span>
                </div>
            </div>

            <div className="mentor-page-body">
                <img src="/mentor-placeholder.png" alt="Mentor image" className="mentor-page__image" />
                <div className="mentor-page-content">
                    <h2 className="mentor-page__position">Главный менеджер</h2>
                    <p className="mentor-page__text">Подробная информация про наставника</p>
                    <p className="mentor-page__experience">Срок работы: 6 лет</p>
                    <button className="mentor-page__button" onClick={() => {
                        dispatch(saveMentor({
                            id: 0,
                            experience: 0,
                            name: '',
                            position: ''
                        })
                        );
                        navigate('/plan')
                    }}>Выбрать наставника</button>
                </div>
            </div>
        </main>
    );
};