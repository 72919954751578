import { useNavigate } from 'react-router-dom';
import { useSidebarDesperate } from '../hooks/mainHooks';
import './Training.scss';
export const Training = () => {
    useSidebarDesperate(true)
    const navigate = useNavigate()
    return (
        <main className={'training-page'}>
            <header className={'training-page-header'}>
                <div className="training-page-level">Уровень 1</div>
                <div className="training-page-pre-text">Подробное описание уровня
                </div>
                <div className="training-page-stepper">
                    01/30
                </div>

                <svg className='training-page-venom' width="273" height="130" viewBox="0 0 273 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M7.22643 130H272.343C270.444 110.359 263.956 91.8018 258.621 85.3365C251.74 76.9964 247.115 78.5028 241.549 80.3157C237.687 81.5738 233.371 82.9795 227.534 81.3437C220.821 79.4626 217.99 65.2426 215.01 50.2778C211.665 33.4784 208.133 15.7403 198.714 13.4661C187.491 10.7566 181.411 23.6571 176.667 33.7245C173.882 39.6338 171.557 44.5671 168.922 44.7943C166.033 45.0434 164.848 39.4847 163.337 32.3984C161.123 22.0089 158.208 8.33558 148.197 4.86627C138.522 1.51348 134.727 9.21159 130.853 17.068C127.985 22.8859 125.074 28.7906 119.7 30.3588C114.221 31.9577 111.485 27.4871 107.843 21.5362C103.089 13.7698 96.792 3.48184 80.8412 0.873422C79.3953 0.636973 78.0483 0.523495 76.7945 0.523438C53.9344 0.522396 62.0287 38.223 65.7915 55.7492C65.8436 55.9919 65.8949 56.2307 65.9452 56.4655C69.054 70.9624 67.2405 74.7913 65.9452 74.8936C64.033 76.2806 61.3862 74.6732 57.3995 72.2521C49.9091 67.7031 37.6888 60.2816 16.7237 64.4509C-7.3368 69.2359 -0.586583 103.023 7.22643 130Z"
                        fill="#874088" />
                </svg>

            </header>
            <div className="training-page-content">
                <div className="training-page-item active" onClick={() => { navigate('/intership') }}>
                    <div className="training-page-item-circle" />
                    <div className="training-page-item-content">
                        <span className="training-page-item-name">Глава 1</span>
                        <span className="training-page-item-text">Подробное описание главы </span>
                    </div>
                    <button className="training-page-item-go-btn" type={'button'}>
                        <svg className="training-page-item-arrow" width="9" height="16" viewBox="0 0 9 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.00043 14.4453L7.72266 7.72309L1.00044 1.00087" stroke="#BCC4E2" stroke-width="2"
                                strokeLinecap="round" strokeLinejoin="bevel" />
                        </svg>
                    </button>
                </div>
                <div className="training-page-item">
                    <div className="training-page-item-circle active" />
                    <div className="training-page-item-content">
                        <span className="training-page-item-name">Глава 2</span>
                        <span className="training-page-item-text">Подробное описание главы </span>
                    </div>
                    <button className="training-page-item-go-btn" type={'button'}>
                        <svg className="training-page-item-arrow" width="9" height="16" viewBox="0 0 9 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.00043 14.4453L7.72266 7.72309L1.00044 1.00087" stroke="#BCC4E2" stroke-width="2"
                                strokeLinecap="round" strokeLinejoin="bevel" />
                        </svg>
                    </button>
                </div>
                <div className="training-page-item">
                    <div className="training-page-item-circle active" />
                    <div className="training-page-item-content">
                        <span className="training-page-item-name">Глава 3</span>
                        <span className="training-page-item-text">Подробное описание главы </span>
                    </div>

                    <button className="training-page-item-go-btn" type={'button'}>
                        <svg className="training-page-item-arrow" width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.00043 14.4453L7.72266 7.72309L1.00044 1.00087" stroke="#BCC4E2" stroke-width="2"
                                strokeLinecap="round" strokeLinejoin="bevel" />
                        </svg>
                    </button>
                </div>
            </div>
        </main>
    );
};