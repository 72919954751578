import {FC, FormEvent, useState} from 'react';
import './HRDepartmentPage.scss';
import {SearchIcon} from "../../assets/icons/SearchIcon";
import {Button} from "../../components/Button/Button";
import {PlusIcon} from "../../assets/icons/PlusIcon";
import {Modal} from "../../components/Modal/Modal";
import {Input} from "../../components/Input/Input";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {CopyIcon} from "../../assets/icons/CopyIcon";

export interface IHrDepartmentPageProps {

}

export const HRDepartmentPage: FC<IHrDepartmentPageProps> = () => {
    return (
        <main className={'hr-department-page'}>
            <header className={'hr-department-page-header'}>
                <h1 className="hr-department-page-title">
                    Отдел HR
                </h1>
                <div className="hr-department-page-controls">
                    <div className={'hr-department-page-search'}>
                        <SearchIcon className={'hr-department-page-search-icon'}/>
                        <input placeholder={'Поиск'} className={'hr-department-page-search-input'} type="text" name=""
                               id=""/>
                    </div>
                    <Button variant={'grey'}>Добавить сотрудника <PlusIcon/></Button>
                </div>
            </header>

            <HRTable items={tableItems}/>
        </main>
    );
};

export interface HRTableProps {
    items: HRRow[]
}

export interface HRRow {
    id: string,
    name: string,
    phoneNumber: string,
    data?: {/*...*/ }
}

const tableItems: HRRow[] = [
    {
        id: '1',
        name: 'Кропотова Дарья Александровна',
        phoneNumber: '7(928)490-61-31'
    },
    {
        id: '2',
        name: 'Зубов Алексей Михайлович',
        phoneNumber: '7(928)333-66-99'
    },
    {
        id: '3',
        name: 'Сахаров Давид Львович',
        phoneNumber: '7(928)111-22-33'
    },
    {
        id: '4',
        name: 'Колесов Иван Михайлович',
        phoneNumber: '7(928)123-45-67'
    },
    {
        id: '5',
        name: 'Кропотова Дарья Александровна',
        phoneNumber: '7(928)490-61-31'
    },
    {
        id: '6',
        name: 'Зубов Алексей Михайлович',
        phoneNumber: '7(928)333-66-99'
    },
    {
        id: '7',
        name: 'Сахаров Давид Львович',
        phoneNumber: '7(928)111-22-33'
    },
    {
        id: '8',
        name: 'Колесов Иван Михайлович',
        phoneNumber: '7(928)123-45-67'
    }
];

const HRTable: FC<HRTableProps> = ({items}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const openModalHandler = () => setIsModalOpen(true);
    const closeModalHandler = () => setIsModalOpen(false);
    const submitHandler = () => console.log("submit handler");

    return <table className={'hr-table'}>
        <thead className={'hr-table-head'}>
        <tr className={'hr-table-head-row'}>
            <th className={'hr-table-head-cell hr-table-name'}>ФИО</th>
            <th className={'hr-table-head-cell hr-table-phone'}>Номер телефона</th>
            <th className={'hr-table-head-cell hr-table-button'}>Данные для входа</th>
        </tr>
        </thead>
        <tbody className="hr-table-body">
        {items.map(row => {
            return <tr key={row.id} className={'hr-table-body-row'}>
                <td className={'hr-table-body-cell hr-table-name'}>{row.name}</td>
                <td className={'hr-table-body-cell hr-table-phone'}>{row.phoneNumber}</td>
                <td className={'hr-table-body-cell hr-table-button'}><Button size={'medium'}
                                                                             variant={'outline'}
                                                                             onClick={openModalHandler}>Открыть</Button>
                </td>
                <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
                    <EmployeeDataModal onSubmit={submitHandler} onClose={closeModalHandler}/>
                </Modal>
            </tr>
        })}
        </tbody>
    </table>
}

export interface IEmployeeDataModalProps {
    onSubmit: () => void;
    onClose: (v: boolean) => void;
}

const EmployeeDataModal: FC<IEmployeeDataModalProps> = ({
                                                            onSubmit, onClose
                                                        }) => {
    const submitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit?.();
    }
    return <form onSubmit={submitHandler} className={'employee-data-modal'}>
        <button className="employee-data-modal-close" onClick={onClose.bind(null, false)}>
            <CloseIcon/>
        </button>
        <header className={'employee-data-modal-header'}>
            <h1 className="employee-data-modal-title">
                Данные сотрудника
            </h1>
            <small className="employee-data-modal-small">
                Отправьте данные для входа сотруднику
            </small>

        </header>
        <Input className="employee-data-modal-input"
               inputWrapperClassName={"employee-data-modal-input-wrapper"}
               rightContent={<CopyIcon className="employee-data-modal-input-icon"/>}/>
        <Input className="employee-data-modal-input"
               inputWrapperClassName={"employee-data-modal-input-wrapper"}
               rightContent={<CopyIcon className="employee-data-modal-input-icon"/>}/>
        <Button className="employee-data-modal-submit" variant={'purple'}>Поделиться</Button>
    </form>
}