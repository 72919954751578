// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentors-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  gap: 30px;
}
.mentors-slider .swiper-wrapper {
  width: 850px;
}
.mentors-slider__nav-btn {
  background: rgb(115, 25, 117);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 90px;
  color: white;
  flex-shrink: 0;
}
.mentors-slider__nav-btn:disabled {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/MentorsSlider/MentorsSlider.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,6BAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,cAAA;AAAJ;AACI;EACE,kBAAA;AACN","sourcesContent":[".mentors-slider {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: max-content;\n  gap: 30px;\n\n  .swiper-wrapper{\n    width: 850px;\n  }\n\n  &__nav-btn {\n    background: rgba(115, 25, 117, 1);\n    width: 40px;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: none;\n    outline: none;\n    border-radius: 90px;\n    color: white;\n    flex-shrink: 0;\n    &:disabled{\n      visibility: hidden;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
