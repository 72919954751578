import {FC} from 'react';
import './JobTitleTable.scss';
import {Button} from "../Button/Button";
import {CopyIcon} from "../../assets/icons/CopyIcon";

export interface IJob {
    id: string,
    name: string,
    link: string;
}

export interface IJobTitlesTableProps {
    items: IJob[]
}


export const JobTitleTable: FC<IJobTitlesTableProps> = ({items}) => {
    const copyToClipbboardHandler = (item: IJob) => {
        navigator.clipboard.writeText(item.link);
    }
    return <table className={'job-titles-table'}>
        <thead className={'job-titles-table-head'}>
        <tr className={'job-titles-table-head-row'}>
            <th className={'job-titles-table-head-cell job-titles-table-name'}>Должность</th>
            <th className={'job-titles-table-head-cell job-titles-table-link'}>Ссылка</th>
            <th className={'job-titles-table-head-cell job-titles-table-link-copy'}></th>
        </tr>
        </thead>
        <tbody className="job-titles-table-body">
        {items.map(row => {
            return <tr key={row.id} className={'job-titles-table-body-row'}>
                <td className={'job-titles-table-body-cell job-titles-table-name'}>{row.name}</td>
                <td className={'job-titles-table-body-cell job-titles-table-link'}><a className={'job-titles-table-body-cell job-titles-table-link-tag'} href={row.link}
                                                                                      target={"_blank"}>{row.link}</a>
                </td>
                <td className={'job-titles-table-body-cell job-titles-table-link-copy'}>
                    <button
                        className={'job-titles-table-copy-button'}
                        onClick={copyToClipbboardHandler.bind(null, row)}>Копировать ссылку
                        <CopyIcon/>
                    </button>
                </td>
            </tr>
        })}
        </tbody>
    </table>
};
