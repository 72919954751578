import React, { useEffect, useRef, useState } from 'react';
import './Video.scss';
import { useNavigate } from 'react-router-dom';
import { sendVideoAPI } from '../../API/videoAPI';
import { getQuestionsThunk } from '../../redux/videoReducer';
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks';
import useRedirect from '../hooks/mainHooks';
import { getActualUserThunk } from '../../redux/surveyReducer';

const Video = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const questions = useAppSelector(state => state.videoReducer.questions)
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [error, setError] = useState(null)
    const [isEnabled, setEnabled] = useState(false)
    const [isRecording, setRecording] = useState(false)
    const [recordedVideoURL, setRecordedVideoURL] = useState<string | null>(null);
    const streamRef = useRef<MediaStream | null>(null)
    const videoRef = useRef<HTMLVideoElement>(null)
    const recorderRef = useRef<MediaRecorder | null>(null)
    const chunksRef = useRef<Array<Blob>>([])
    const [isFirstStep, setIsFirstStep] = useState<boolean>(true);
    const befInterval = useRef<NodeJS.Timer>();
    const durInterval = useRef<NodeJS.Timer>();


    /*  useEffect(() => {
       
        dispatch(getActualUserThunk())
    }, []) */
    
    useRedirect((!localStorage.getItem('token')), '/sign-in') 

    let [seconds, setSeconds] = useState<number>(5);
    let [duration, setDuration] = useState<number>(0);
    const startStream = () => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const constraints: MediaStreamConstraints = {
                audio: devices.some(device => device.kind === 'audioinput'),
                video: devices.some(device => device.kind === 'videoinput')
            };
            navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                streamRef.current = stream;
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    if (videoRef.current) {
                        videoRef.current.onloadedmetadata = () => {
                            videoRef.current && videoRef.current.play()
                        };
                    }
                }
                const recorder = new MediaRecorder(stream)
                recorderRef.current = recorder
                recorder.ondataavailable = (event) => {
                    if (event.data && event.data.size > 0) {
                        chunksRef.current.push(event.data)
                    }
                }
            }).catch((err) => {
                setError(err.name)
            });
        }).catch((err) => {
            setError(err.name)
        });
        setEnabled(true)
    }

    const startRecording = () => {
        if (recorderRef.current && streamRef.current) {
            chunksRef.current = []
            recorderRef.current.start()
            setRecording(true)
        }
    }
    const stopRecording = () => {
        if (recorderRef.current) {
            recorderRef.current.onstop = () => {
                const blob = new Blob(chunksRef.current);
                const url = URL.createObjectURL(blob);
                const formData = new FormData()
                formData.append('video', blob, 'video.mp4')
                setRecordedVideoURL(url);
                sendVideoAPI(blob, questions[currentQuestion].id).then(res => {
                    console.log(res);
                }).catch(error => {
                    if (error.response) {
                        console.log('Server Error:', error.response.data);
                        console.log('Status Code:', error.response.status);
                        console.log('Headers:', error.response.headers);
                    } else if (error.request) {
                        console.log('Request Error:', error.request);
                    } else {
                        console.log('Error:', error.message);
                    }
                    setError(error);
                })
                console.log(url)
            };

            chunksRef.current = [];
            recorderRef.current.stop();
            setRecording(false);
        }
    };

    const stopStream = () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => track.stop())
            stopRecording()
        }
        setEnabled(false)
    }

    const nextQuestion = () => {
        if (isRecording) {
            stopRecording();
        }
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            navigate('/videosuccess');
        }
    };

    useEffect(() => {
        if (!isFirstStep) dispatch(getQuestionsThunk()).then(res => console.log(res))
    }, [isFirstStep]);

    useEffect(() => {
        setError(null)
        stopStream()
        if (isEnabled) startStream()
    }, [isEnabled])


    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    useEffect(() => {
        if (isRecording) {
            durInterval.current = setInterval(() => setDuration((prev) => prev + 1), 1000)
        }
        else {
            clearInterval(durInterval.current);
            setSeconds(5)
            setDuration(0);
        }
    }, [isRecording, currentQuestion]);

    useEffect(() => {
        if (isFirstStep) return;
        clearInterval(befInterval.current);
        seconds = 5;
        setSeconds(5);
        befInterval.current = setInterval(() => {
            setSeconds((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(befInterval.current);
    }, [isFirstStep, currentQuestion]);

    useEffect(() => {
        if (seconds === 0 || isRecording) {
            if (!isRecording) startRecording();
            else setSeconds(prev => 0);

            clearInterval(befInterval.current);
        }
    }, [seconds, isRecording]);

    useEffect(() => {
        if (isRecording && duration === 61) {
            nextQuestion()
        }
    }, [duration, isRecording])


    return (
        <main className="video-page">
            isRecording____{String(isRecording)}
            <div className="video-page-body">
                <div className="video-page-camera-block">
                    <div className="video-page-duration">
                        <span className="video-page-duration__record-icon" />
                        <span className="video-pagr-duration__value">{formatTime(duration)}</span>
                    </div>
                    <button className="video-page__full-button">
                        <svg className="video-page__full-button-icon" width="28" height="28" viewBox="0 0 28 28"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.3335 10.5V7.58334C2.3335 4.67834 4.6785 2.33334 7.5835 2.33334H10.5002"
                                stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" />
                            <path d="M17.5 2.33334H20.4167C23.3217 2.33334 25.6667 4.67834 25.6667 7.58334V10.5"
                                stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" />
                            <path
                                d="M25.6665 18.6667V20.4167C25.6665 23.3217 23.3215 25.6667 20.4165 25.6667H18.6665"
                                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.5002 25.6667H7.5835C4.6785 25.6667 2.3335 23.3217 2.3335 20.4167V17.5"
                                stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" />
                        </svg>
                    </button>

                    <button className="video-page__noise-button">
                        <svg className="video-page__noise-button-icon" width="38" height="38" viewBox="0 0 38 38"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 11L5 28" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round"
                                strokeLinejoin="round" />
                            <path d="M12 13L12 26" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round"
                                strokeLinejoin="round" />
                            <path d="M19 5.14583V32.8542" stroke="currentColor" strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round" />
                            <path d="M26 13V26" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round"
                                strokeLinejoin="round" />
                            <path d="M33 11L33 28" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round"
                                strokeLinejoin="round" />
                        </svg>

                    </button>

                    <video playsInline autoPlay ref={videoRef} muted={!isFirstStep} className="video-page__video" />
                </div>
                {isFirstStep && (<div className="video-page-content">
                    <h1 className="video-page__title">
                        Проверка видеозаписи
                    </h1>
                    <span className="video-page__small">
                        Перед тем как начать видео интервью удостоверьтесь в корректной работе аппаратуры. После
                        нажатия на кнопку начнется запись и Вы сможете проверить работу микрофона и камеры
                    </span>
                    <button className="video-page__check-button" onClick={startStream}>
                        <div className="video-page__check-button-icon-wrapper">
                            <svg className="video-page__check-button-icon" width="28" height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.6185 23.8233H7.24516C3.5585 23.8233 2.3335 21.3733 2.3335 18.9117V9.08833C2.3335 5.40167 3.5585 4.17667 7.24516 4.17667H14.6185C18.3052 4.17667 19.5302 5.40167 19.5302 9.08833V18.9117C19.5302 22.5983 18.2935 23.8233 14.6185 23.8233Z"
                                    stroke="white" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path
                                    d="M22.7734 19.95L19.53 17.675V10.3133L22.7734 8.03833C24.36 6.93 25.6667 7.60667 25.6667 9.555V18.445C25.6667 20.3933 24.36 21.07 22.7734 19.95Z"
                                    stroke="white" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path
                                    d="M13.4167 12.8333C14.3832 12.8333 15.1667 12.0498 15.1667 11.0833C15.1667 10.1168 14.3832 9.33334 13.4167 9.33334C12.4502 9.33334 11.6667 10.1168 11.6667 11.0833C11.6667 12.0498 12.4502 12.8333 13.4167 12.8333Z"
                                    stroke="white" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        Проверить видеозапись
                    </button>
                    <button className="video-page__btn video-page__btn--orange" onClick={() => {
                        setIsFirstStep(false);
                        startStream()
                    }}>
                        Перейти к интервью
                    </button>
                </div>)}
                {!isFirstStep && (<div className="video-page-content video-page-content--alt">
                    <div className="video-page-starts-at">
                        <span className="video-page-starts-at__text">
                            Запись начнётся через:
                        </span>
                        <div className="video-page-starts-at-countdown">
                            {formatTime(seconds)}
                        </div>
                    </div>
                    <div className="video-page-question">
                        <h3 className="video-page-question__title">Вопрос {currentQuestion + 1}. {questions[currentQuestion]?.['question_text']}</h3>
                        <span className="video-page-question__content">
                            Ответ на вопрос может включать в себя следующую информацию: с какой целью Вы ищете работу, что Вы умеете, где Вы обучались и работали, почему Вас заинтересовала данная вакансия, почему Вы считаете, что подходите для работы в компании.
                        </span>
                    </div>

                    <button className="video-page__btn video-page__btn--purple" disabled={isRecording}
                        onClick={() => {
                            if (!isRecording && isEnabled) startRecording();
                        }}>
                        Начать запись
                    </button>

                    {isRecording && <button className="video-page__btn video-page__btn--orange" onClick={() => {
                        isRecording && nextQuestion()
                    }}>
                        Отправить ответ на вопрос
                    </button>
                    }
                </div>)}
            </div>
        </main>
    );
};

export default Video;

