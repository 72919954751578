import {FC, SVGProps} from 'react';

export const HiddenEyeIcon: FC<SVGProps<SVGSVGElement>> = ({...restProps}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M9.2497 12.55C8.8376 12.6279 8.4191 12.667 7.9997 12.6667C5.01436 12.6667 2.4877 10.7047 1.6377 8C1.86654 7.27207 2.21902 6.58898 2.6797 5.98067M6.58503 6.586C6.96013 6.2109 7.46888 6.00016 7.99936 6.00016C8.52984 6.00016 9.03859 6.2109 9.4137 6.586C9.7888 6.9611 9.99953 7.46986 9.99953 8.00033C9.99953 8.53081 9.7888 9.03956 9.4137 9.41467M6.58503 6.586L9.4137 9.41467M6.58503 6.586L9.41303 9.41333M9.4137 9.41467L11.6077 11.608M6.58636 6.58667L4.39303 4.39333M4.39303 4.39333L1.9997 2M4.39303 4.39333C5.46793 3.69971 6.72043 3.3316 7.9997 3.33333C10.985 3.33333 13.5117 5.29533 14.3617 8C13.8924 9.48697 12.9179 10.763 11.607 11.6073M4.39303 4.39333L11.607 11.6073M11.607 11.6073L13.9997 14"
                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
