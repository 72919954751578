// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hrlist {
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 50px;
  border-radius: 24px;
  padding: 24px;
  background-color: #ffffff;
}
.hrlist-title {
  font-weight: 700;
}
.hrlist-header {
  padding: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.hrlist-table {
  width: 100%;
  border-collapse: collapse;
}
.hrlist-table thead tr, .hrlist-table td:not(.table-link) {
  border-bottom: 1px solid #DCD7FE;
}
.hrlist-table th, .hrlist-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  padding: 20px;
  text-align: left;
}
.hrlist-table th {
  font-weight: bold;
  color: #6B7280;
}
.hrlist-table tr a {
  color: #731975;
  text-decoration: underline;
}
.hrlist-table .table-link-faCopy {
  color: #731975;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/routes/MainPanel/HRList/HRList.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;AAAJ;AACI;EAEI,gBAAA;AAAR;AAEI;EAEQ,aAAA;EACA,aAAA;EACA,WAAA;EACA,8BAAA;AADZ;AAGI;EAEI,WAAA;EACA,yBAAA;AAFR;AAIS;EACG,gCAAA;AAFZ;AAIS;EACG,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;AAFZ;AAIS;EACG,iBAAA;EACA,cAAA;AAFZ;AAKQ;EACI,cAAA;EACA,0BAAA;AAHZ;AAQY;EAEI,cAAA;EACA,iBAAA;AAPhB","sourcesContent":[".hrlist\n{\n    max-width: 1280px;\n    margin: 0 auto;\n    margin-top: 50px;\n    border-radius: 24px;\n    padding: 24px;\n    background-color: #ffffff;\n    &-title \n    {\n        font-weight: 700;\n    }\n    &-header \n        {\n            padding: 15px;\n            display: flex;\n            width: 100%;\n            justify-content: space-between;\n        }\n    &-table\n    {    \n        width: 100%;\n        border-collapse: collapse;\n       \n         thead tr, td:not(.table-link)  {\n            border-bottom: 1px solid #DCD7FE;\n        }\n         th, td {\n            white-space: nowrap;      \n            overflow: hidden;         \n            text-overflow: ellipsis;  \n            max-width: 150px;  \n            padding: 20px;\n            text-align: left; \n        }\n         th {\n            font-weight: bold;\n            color: #6B7280;\n        }\n\n        tr a {\n            color: #731975;\n            text-decoration: underline;\n        }\n\n        .table-link \n        {\n            &-faCopy \n            {\n                color: #731975;\n                margin-left: 10px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
