import {ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode} from 'react';
import './RoundButton.scss';

export interface IRoundButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children: ReactNode;
    variant: 'red' | 'blue'
}

export const RoundButton: FC<IRoundButtonProps> = ({children, variant = 'blue'}) => {
    return (
        <button className={`round-button-${variant}`}>
            {children}
        </button>
    );
};
