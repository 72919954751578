// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-block {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}
.input-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
.input-block-content {
  height: 42px;
  background: rgb(249, 250, 251);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  overflow: hidden;
}
.input-block-input {
  flex-grow: 1;
  padding: 12px 16px;
  height: 100%;
}
.input-block-input::placeholder {
  color: rgb(107, 114, 128);
}
.input-block-right-content {
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oBAAA;EACA,uBAAA;EACA,sBAAA;EACA,QAAA;AACF;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,YAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,gBAAA;AAAJ;AAGE;EACE,YAAA;EACA,kBAAA;EACA,YAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAKE;EACE,cAAA;AAHJ","sourcesContent":[".input-block {\n  display: flex;\n  align-items: stretch;\n  justify-content: center;\n  flex-direction: column;\n  gap: 8px;\n\n\n  &-header{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 21px;\n    text-align: left;\n  }\n  &-content {\n    height: 42px;\n    background: rgba(249, 250, 251, 1);\n    border-radius: 8px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 5px;\n    overflow: hidden;\n  }\n\n  &-input {\n    flex-grow: 1;\n    padding: 12px 16px;\n    height: 100%;\n\n    &::placeholder {\n      color: rgba(107, 114, 128, 1);\n    }\n  }\n\n  &-right-content {\n    flex-shrink: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
