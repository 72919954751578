import {FC, SVGProps} from 'react';

export const MicrophoneIcon: FC<SVGProps<SVGSVGElement>> = ({...restProps}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M13.9997 18.0835C16.578 18.0835 18.6663 15.9952 18.6663 13.4168V7.00016C18.6663 4.42183 16.578 2.3335 13.9997 2.3335C11.4213 2.3335 9.33301 4.42183 9.33301 7.00016V13.4168C9.33301 15.9952 11.4213 18.0835 13.9997 18.0835Z"
                stroke="currentColor" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M5.0752 11.2583V13.2416C5.0752 18.165 9.07686 22.1666 14.0002 22.1666C18.9235 22.1666 22.9252 18.165 22.9252 13.2416V11.2583"
                stroke="currentColor" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 22.1665V25.6665" stroke="currentColor" strokeWidth="2.25" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};
