import { MentorsSlider } from "../../components/MentorsSlider/MentorsSlider";
import './MentorsPage.scss';
const mentors = [
    {
        id: 1,
        experience: 6,
        name: "Илона Бекоева",
        position: "менеджер"
    },
    {
        id: 2,
        experience: 5,
        name: "Мария Зайцева",
        position: "менеджер"
    },
    {
        id: 3,
        experience: 9,
        name: "Ирина Миронова",
        position: "менеджер"
    },
    {
        id: 4,
        experience: 10,
        name: "Алёна Козырева",
        position: "менеджер"
    },
]
export const MentorsPage = () => {
    return (
        <div className='mentors-page page'>
            <h1 className="mentors-page__title">
                Наставники компании
            </h1>
            <p className="mentors-page__text">
                На протяжении всей стажировки с Вами на связи будет специалист. Он поможет Вам справиться с любыми трудностями, ответит на все вопросы, возникающие в процессе прохождения адаптации. Не стесняйтесь обращаться за помощью и поддержкой! Пусть Ваше обучение будет продуктивным и интересным, а новые знания и навыки помогут Вам скорее достичь карьерных высот.
            </p>
            <MentorsSlider mentors={mentors} />
        </div>
    );
};