import React, { useEffect, useState } from "react";
import './Breadcrumbs.scss';
import { Breadcrumb } from "./Breadcrumb";
import { useLocation } from "react-router-dom";



export const Breadcrumbs = () => {
    const roadmap: { [key: string]: string } = {
        '1': '/',
        '2': '/entersuccess',
        '3': '/questionary',
        '4': '/video',
        '5': '/test'
    };

    const location = useLocation();
    const [currentRoute, setCurRoute] = useState<string>(location.pathname)
    const [currentRouteNumber, setCurRouteNumber] = useState<string | undefined>('1')

    const routes = [<Breadcrumb route={roadmap['1']} currentRoute={currentRoute}>Главная</Breadcrumb>,
    <Breadcrumb route={roadmap['2']} currentRoute={currentRoute}>Вход</Breadcrumb>,
    <Breadcrumb route={roadmap['3']} currentRoute={currentRoute}>Анкета</Breadcrumb>,
    <Breadcrumb route={roadmap['4']} currentRoute={currentRoute}>Видеоинтервью</Breadcrumb>,
    <Breadcrumb route={roadmap['5']} currentRoute={currentRoute}>Тесты</Breadcrumb>]


    useEffect(() => {
        setCurRoute(location.pathname)
        let temp = Object.keys(roadmap).find(key => roadmap[key] === location.pathname)
        setCurRouteNumber(temp)

    }, [location.pathname])

    const filterRoute = () => {
        const routeIndex = parseInt(currentRouteNumber || '0') - 1;
        let res = []

        for (let i = 0; i < routes.length; i++) {

            if (routeIndex >= 0 && i <= routeIndex) {
                res.push(routes[i]);
            }
        }
        return res
    }

    return (
        <div className='breadcrumbs'>
            {filterRoute()}
        </div>
    );
};


