import './Workers.scss';
export const Workers = () => {
    return (
        <main className={'workers-page'}>
            <h1 className="workers-page__title">
                Организационная структура
            </h1>

            <img className='workers-page__img' src="/ierarchy.png"  alt="Workers"/>
        </main>
    );
};