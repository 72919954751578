import { useEffect, useState } from 'react';
import { AnswerType, getPsychoQuestions, setUserAnswer } from '../../redux/psychoReducer';
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks';
import './Test.scss';
import { useNavigate } from 'react-router-dom';
import useRedirect from '../hooks/mainHooks';
import { sendTestQuestionsAPI } from 'API/testAPI';

export type ResultType =
    {
        test_question_id: number,
        test_answer_id: number

    }

export const Test = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const questions = useAppSelector(state => state.psychoReducer.fullQuestions)
    const [currentQ, setCurQ] = useState(0)
    const [result, setResult] = useState<ResultType[]>([]);
    useRedirect((!localStorage.getItem('token')), '/sign-in')

    const nextQuestion = () => {
        if (!questions[currentQ].answer) {
            alert("Пожалуйста, выберите ответ на текущий вопрос");
            return;
        }
        if (currentQ < questions.length - 1) {
            setResult(prevResult => [
                ...prevResult,
                {
                    test_question_id: questions[currentQ].id,
                    test_answer_id: questions[currentQ].answer.id
                }
            ]);

            setCurQ(currentQ + 1)
        }
        else {
            setResult(prevResult => [
                ...prevResult,
                {
                    test_question_id: questions[currentQ].id,
                    test_answer_id: questions[currentQ].answer.id
                }
            ]);
        }
    }

    /*    const finish = () => {
           sendTestQuestionsAPI(result)
          
       } */

    const prevQuestion = () => {
        if (currentQ > 0)
            setCurQ(currentQ - 1)
    }

    useEffect(() => {
        if (result.length === 13) {
            sendTestQuestionsAPI(result).then(res => { }).catch(err => console.log(err)).finally(() => navigate('/meta-test'));
        }
    }, [result]);

    useEffect(() => {
        dispatch(getPsychoQuestions())
    }, [])

    return (
        <main className={'test-page'}>
            <h1 className="test-page__title">
                Тест на определение психотипа
            </h1>
            <span className="test-page__text">
                Данное тестирование поможет нам определить Ваши социальные и коммуникативные навыки, сильные и слабые стороны характера, предрасположенность к определённой должности.
            </span>
            {questions && questions[currentQ] && <div className="test-page-quest">
                <h4 className="test-page-quest__title">{currentQ + 1}. {questions[currentQ].question}</h4>
                {questions[currentQ].answers.map((a, i) =>
                (
                    <div className="test-page-quest-answers" key={`${i}`}>
                        <label className='test-page-quest-answer' htmlFor={`${a.id}`}>
                            <div className="test-page-quest__check-wrapper"><input required
                                checked={questions[currentQ].answer === a}
                                className='test-page-quest__check'
                                onChange={() => dispatch(setUserAnswer({
                                    questionIndex: currentQ,
                                    answer: a
                                }))} type="radio"
                                name={`question-${questions[currentQ].id}`}
                                id={`${a.id}`} /></div>
                            {a.answer}
                        </label>
                    </div>
                ))}
                <div className="test-page-count">
                    {currentQ + 1 !== questions.length && <div>{currentQ + 1} ... {questions.length}</div>}
                    {currentQ + 1 === questions.length &&
                        <button onClick={nextQuestion/* finish */} className='test-page__finish-button'>Завершить тест</button>}
                </div>
                {currentQ !== 0 &&
                    <div onClick={prevQuestion} className="test-page-quest__button test-page-quest__button--prev">
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.66683 7L16.3335 7M1.66683 7L7.16683 1.5M1.66683 7L7.16683 12.5" stroke="white"
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>}
                {
                    currentQ + 1 < questions.length &&
                    <div onClick={nextQuestion} className="test-page-quest__button test-page-quest__button--next">
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.3332 7L1.6665 7M16.3332 7L10.8332 1.5M16.3332 7L10.8332 12.5" stroke="white"
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                }
            </div>}
        </main>
    );
};