import './RoadmapSidebar.scss';
import { useLocation } from "react-router-dom";
import { PlanNavIcon } from "../Icons/PlanNavIcon";
import { Chevron } from "../Icons/Chevron";
import { useEffect, useState } from "react";
import { saveSidebarState } from '../../redux/roadmapReducer';
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks';

const links = [
    {
        path: '/plan',
        icon: <PlanNavIcon />,
        label: "План стажировки"
    },
    {
        path: '/training',
        icon: <PlanNavIcon />,
        label: "Обучение"
    },
    {
        path: '/chats',
        icon: <PlanNavIcon />,
        label: "Обратная связь"
    },
]

interface RoadmapSidebarProps {
    className?: string
}

export const RoadmapSidebar = ({ className = "" }: RoadmapSidebarProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(localStorage.getItem('sidebarState') === 'true');

    const dispatch = useAppDispatch()
    const location = useLocation();
    return (
        <aside className={`roadmap-sidebar ${isOpen ? 'open' : ''} ${className} `}>
            <header className="roadmap-sidebar__head">
                <img className="roadmap-sidebar-logo" src="/actual-logo.svg" alt="SkillRadar" />
                <button className="roadmap-sidebar__toggle-button" onClick={() => {
                    const newState = !isOpen;
                    setIsOpen(newState);
                    localStorage.setItem('sidebarState', newState.toString());
                }}>
                    <Chevron />
                </button>

            </header>
            <div className="roadmap-sidebar__body">
                <div className="roadmap-sidebar__active-el" />
                {
                    links.map(({ path, icon, label }) => {
                        const isActive = location.pathname === path;
                        return (
                            <a href={path} className={`roadmap-sidebar__link ${isActive ? 'active' : ''}`}>
                                <>
                                    <div className={`roadmap-sidebar__link-icon`}>{icon}</div>
                                    <span className={`roadmap-sidebar__link-label`}>{label}</span>
                                </>
                            </a>
                        )
                    })
                }
            </div>
        </aside>
    );
};