import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type AuthType =
{
    isAuth: boolean
    phoneNumber: string
    step: number
}

let initialState = 
{
    isAuth: false,
    phoneNumber: '',
    step: 1
}

export type initialState = typeof initialState;

const authSlice = createSlice({
    name: 'auth',
    initialState,
     reducers: {
        setNumber(state, action: PayloadAction<string>)
        {
            state.phoneNumber = action.payload;
        },
        setStep(state, action: PayloadAction<number>)
        {
            state.step = action.payload;
        },
        setAuth(state, action: PayloadAction<boolean>)
        {
            state.isAuth = action.payload;
        }
    }
})

export const {setAuth, setNumber, setStep} = authSlice.actions;
export default authSlice.reducer;