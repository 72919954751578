import axios from "axios";

export const instance = axios.create({
    baseURL: "https://survey.skill-radar.ru",
  });
  
  instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
  });
  