import Header from "components/Header/Header"
import { Outlet, useLocation } from "react-router-dom"
import './AdminLayout.scss'
import logonew from './logo1.png'
import { useEffect, useState } from "react";
import { Chevron } from './../../components/Icons/Chevron';
import { PlanNavIcon } from "components/Icons/PlanNavIcon";

export const AdminLayout = () => {
    const [linkActive, setLinkActive] = useState<null | number>(null)
    const [isClosed, setIsClosed] = useState<boolean>(localStorage.getItem('adminSidebar') === 'false')
    const location = useLocation()
    const links = [
        {
            id: 0,
            icon: <PlanNavIcon />,
            href: 'hrlist',
            text: 'Отдел HR'
        },
        {
            id: 1,
            icon: <PlanNavIcon />,
            href: "private-office",
            text: "Личный кабинет"
        },
        {
            id: 2,
            icon: <PlanNavIcon />,
            href: "settings-panel",
            text: "Настройки"
        },
        {
            id: 3,
            icon: <PlanNavIcon />,
            href: "directions",
            text: "Направления"
        },
    ]


    useEffect(() => {
        const activeLink = links.find(link => location.pathname.endsWith(`admin/${link.href}`));
        if (activeLink) {
            setLinkActive(activeLink.id);
        }
    }, [location.pathname, links]);


    return <div>
        <div className="admin-layout">
            <div className={`admin-layout__sidebar ${!isClosed ? 'closed' : ''} `}>
                <div className="admin-layout__sidebar-logo">
                    <img src={logonew} alt="" />
                    <button onClick={() => {
                        setIsClosed(!isClosed);
                        localStorage.setItem('adminSidebar', isClosed.toString())
                    }}>
                        <Chevron />
                    </button>
                </div>
                <div className="admin-layout__sidebar-routes">
                    {links.map((l) => {
                        return <div className={`route ${linkActive === l.id ? 'active' : ''}`}>
                            <div className="admin-route-icon">
                                {l.icon}
                            </div>
                            <a className="admin-route-text" href={l.href}>{l.text}</a>
                        </div>
                    })}
                </div>
            </div>
            <div className="admin-layout__content">
                <Header onlyLogo={false} isShort={true}></Header>
                <Outlet />
            </div>
        </div>
    </div>
}