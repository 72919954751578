// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes transform-bounce {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes transform-max-width {
  0% {
    width: 0;
  }
}
.link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: 400;
  transition: 0.25s;
  color: rgb(107, 114, 128);
  stroke: currentColor;
}
.link-button-right {
  flex-direction: row-reverse;
}
.link-button-right .link-button-chevron {
  transform: rotate(180deg);
}
.link-button:hover {
  opacity: 0.9;
  gap: 5px;
}
.link-button:active {
  transition: 0s;
  transform: scale(0.94);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.scss","webpack://./src/components/LinkButton/LinkButton.scss"],"names":[],"mappings":"AASA;EACE;IACE,mBAAA;ECRF;EDUA;IACE,sBAAA;ECRF;EDUA;IACE,mBAAA;ECRF;EDUA;IACE,sBAAA;ECRF;EDUA;IACE,mBAAA;ECRF;AACF;ADYA;EACE;IACE,QAAA;ECVF;AACF;AApBA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBDRK;ECSL,oBAAA;AAqBF;AApBE;EACE,2BAAA;AAsBJ;AApBE;EACE,yBAAA;AAsBJ;AApBE;EACE,YAAA;EACA,QAAA;AAsBJ;AApBE;EACE,cAAA;EACA,sBAAA;AAsBJ","sourcesContent":["$darkblue-1: #2C2F48;\n\n$purple: rgba(115, 25, 117, 1);\n$red: rgba(224, 36, 36, 1);\n$grey: rgba(107, 114, 128, 1);\n$light-grey: rgba(181, 183, 192, 1);\n$light-purple: rgba(242, 239, 255, 1);\n$pre-black: rgba(49, 49, 49, 1);\n\n@keyframes transform-bounce {\n  0%{\n    transform: scale(0);\n  }\n  25%{\n    transform: scale(1.25);\n  }\n  50%{\n    transform: scale(1);\n  }\n  75%{\n    transform: scale(1.25);\n  }\n  100%{\n    transform: scale(1);\n  }\n}\n\n\n@keyframes transform-max-width {\n  0%{\n    width: 0;\n  }\n}\n","@import '/src/assets/styles/variables';\n.link-button{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 2px;\n  border:none;\n  outline: none;\n\n  font-size: 20px;\n  font-weight: 400;\n  transition: 0.25s;\n  color: $grey;\n  stroke: currentColor;\n  &-right{\n    flex-direction: row-reverse;\n  }\n  &-right &-chevron{\n    transform: rotate(180deg);\n  }\n  &:hover{\n    opacity: 0.9;\n    gap: 5px;\n  }\n  &:active{\n    transition: 0s;\n    transform: scale(0.94);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
