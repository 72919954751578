import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type mentorType = 
{
    id: number,
    experience: number,
    name: string,
    position: string
}

let initialState = {
    mentorSelected: false,
    mentor: {} as mentorType,
    sideBarOpened: false,
}

export type initialStateType = typeof initialState;

const roadmapSlice = createSlice({
    name: 'roadmap',
    initialState,
    reducers: {
        saveMentor(state, action: PayloadAction<mentorType>) 
        {
            state.mentor = action.payload;
            state.mentorSelected = true;
        },
        saveSidebarState(state, action: PayloadAction<boolean>)
        {
            debugger
            state.sideBarOpened = action.payload
        }
    }
})

export default roadmapSlice.reducer;
export const {saveMentor, saveSidebarState} = roadmapSlice.actions