import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/reduxHooks";
import { saveMentor } from "../../redux/roadmapReducer";

const useRedirect = (condition: boolean, to: string) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (condition) navigate(to);
  }, [navigate, to]);
};

export default useRedirect;

export const useLogCheck = () => {
  useRedirect(!localStorage.getItem("token"), "/sign-in");
};

export const useNoAuthCheck = () => {
  useRedirect(!!localStorage.getItem("token"), "/entersuccess");
};

export const useSidebarDesperate = (flag: boolean) => 
  {
    const dispatch = useAppDispatch()
      if (flag) dispatch(saveMentor({id: 1, experience: 1, name: '', position: ''}))
   
  }