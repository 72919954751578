import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VideoQuestion } from "../types/types";
import { getQuestionsAPI } from "../API/videoAPI";

let initialState = {
  questions: [] as VideoQuestion[],
};

type initialStateType = typeof initialState;

export const getQuestionsThunk = createAsyncThunk(
  "video/getQuestionsThunk",
  async (_, { dispatch }) => {
    getQuestionsAPI().then((res) => {
      if (res.status === 200) {
        dispatch(setQuestions(res.data));
      }
    });
  }
);

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setQuestions(state, action: PayloadAction<VideoQuestion[]>) {
      state.questions = action.payload;
    },
  },
});

export const { setQuestions } = videoSlice.actions;
export default videoSlice.reducer;
