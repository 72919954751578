// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hello {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: stretch;
  max-width: 887px;
  margin: 0 auto;
}
.hello__title {
  color: white;
  font-weight: 500;
  font-size: 64px;
  margin-bottom: 55px;
}
.hello__text {
  color: white;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 35px;
}
.hello__next-btn {
  padding: 19px 25px;
  color: white;
  border-radius: 19px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 20px auto 0;
}`, "",{"version":3,"sources":["webpack://./src/routes/Hello/Hello.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,oBAAA;EACA,gBAAA;EACA,cAAA;AACJ;AACI;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AACN;AAEI;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AAAN;AAGI;EACI,kBAAA;EACA,YAAA;EACA,mBAAA;EACF,uBAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,aAAA;EACA,mBAAA;AAFR","sourcesContent":[".hello {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    align-items: stretch;\n    max-width: 887px;\n    margin: 0 auto;\n  \n    &__title {\n      color: white;\n      font-weight: 500;\n      font-size: 64px;\n      margin-bottom: 55px;\n    }\n  \n    &__text {\n      color: white;\n      font-weight: 400;\n      font-size: 20px;\n      margin-bottom: 35px;\n    }\n\n    &__next-btn {\n        padding: 19px 25px;\n        color: white;\n        border-radius: 19px;\n      border: 1px solid white;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        \n        outline: none;\n        margin: 20px auto 0;\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
