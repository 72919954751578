import React, { SyntheticEvent, useState } from 'react';
import './Registration.scss';
import { sendCodeAPI, sendNumberAPI } from '../../API/loginAPI';
import { useNavigate } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import useRedirect from '../hooks/mainHooks';

const Login = () => {
    // ЭТОТ КОД ПЕРЕНЕСЕН ИЗ SIGN_IN ////
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
    const [code, setCode] = useState<string | null>(null)
    const [messageSent, setMessageSent] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const navigate = useNavigate()
    useRedirect((!!localStorage.getItem('token')), '/entersuccess')
    // ЭТОТ КОД ПЕРЕНЕСЕН ИЗ SIGN_IN ////

    const handleChangerNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!messageSent)
            setPhoneNumber(event.target.value)
    }
    // ЭТОТ КОД ПЕРЕНЕСЕН ИЗ SIGN_IN ////

    const handleChangerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!codeSent)
            setCode(event.target.value)
    }
    // ЭТОТ КОД ПЕРЕНЕСЕН ИЗ SIGN_IN ////

    const sendNumber = (e: SyntheticEvent) => {
        e.preventDefault();
        if (phoneNumber) {
            sendNumberAPI(phoneNumber).then((res) => console.log(res)).catch(err => {
                console.error(err)
            })
            setMessageSent(true)
        }
    }
    // ЭТОТ КОД ПЕРЕНЕСЕН ИЗ SIGN_IN ////

    const sendCode = (e: SyntheticEvent) => {
        e.preventDefault();
        if (code && phoneNumber) {
            sendCodeAPI(phoneNumber, code).then(res => {
                if (res.status === 200) {
                    localStorage.setItem('token', res.data.jwt)
                    setCodeSent(true)
                    navigate('/entersuccess')
                }
            }).catch(err => console.error(err))

        }
    }
    // ЭТОТ КОД ПЕРЕНЕСЕН ИЗ SIGN_IN ////

    return (
        <main className={'login'}>
            <form onSubmit={sendNumber} className="login-form">
                <h1 className="login-form__title">Вход в систему</h1>
                <small>Мы отправили Вам пароль в SMS<br />на номер +7 (___) ___-__-__</small>
                <label htmlFor={'phone'} className="login-form-input-block">
                    <span className='login-form__label'>Введите пароль из SMS</span>
                    <ReactInputMask id={'phone'} onChange={handleChangerNumber} mask={'99999999999'}
                        className="login-form__input" placeholder='+7 (___) ___ __-__'
                        type="phone" />
                </label>

                <button type="button" onClick={sendNumber} className="login-form__button">Войти</button>
                <a className='login-form--alt__change' href="#">
                    Сменить номер?
                </a>
            </form>
        </main>
    );
};

export default Login;