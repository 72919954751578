import {LinkButton} from "../LinkButton/LinkButton";
import {Button} from "../Button/Button";
import './LinksHeader.scss'
type Props = {
title?: string,
onPageChange: (page: number) => void
handlePositionChange: (page: number) => void
}

export const LinksHeader = ({title = 'Данные анкеты', onPageChange, handlePositionChange}: Props) => {
    return (
        <div className="links-header-head">
            <div className="links-header-wards">
                <LinkButton handlePositionChange={handlePositionChange} onPageChange={onPageChange} direction='left'>Назад</LinkButton>
                <LinkButton handlePositionChange={handlePositionChange} onPageChange={onPageChange} direction='right'>Вперед</LinkButton>
            </div>
            <div className="links-header-actions">
                <h1 className="lato-28-400 links-header-title">
                    {title}
                </h1>
                <div className="links-header-buttons">
                    <Button onClick={() => {
                        debugger
                        handlePositionChange(2)}} variant='red'>Отклонить</Button>
                    <Button onClick={() => handlePositionChange(3)}>Одобрить</Button>
                </div>
            </div>
        </div>
    );
};