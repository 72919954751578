import axios from "axios";
import { instance } from "./main";

export const getSurveys = () => {
  return instance.get("/admin/user/role");
};

export const sendPosition = (role_id: number, user_id: number) => {
  return instance.post("/admin/user/role/set", { role_id, user_id });
};

export const getAnswers = (id: number) => 
  {
    return instance.get(`/admin/user/interview/answers/${id}`)
  }

  export const getResult = (id: number) => 
    {
      return instance.get(`/interview/result/${id}`)
    }