// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes transform-bounce {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes transform-max-width {
  0% {
    width: 0;
  }
}
.job-titles-table-head-cell {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgb(107, 114, 128);
}
.job-titles-table th, .job-titles-table td {
  border-bottom: 1px solid rgb(220, 215, 254);
  height: 53px;
}
.job-titles-table-body .job-titles-table-link-copy {
  color: rgb(115, 25, 117);
}
.job-titles-table-body .job-titles-table-link-tag {
  color: black;
}
.job-titles-table-body .job-titles-table-link-tag:hover {
  text-decoration: underline;
}
.job-titles-table-copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: rgb(115, 25, 117);
}
.job-titles-table-copy-button:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.scss","webpack://./src/components/JobTitleTable/JobTitleTable.scss"],"names":[],"mappings":"AASA;EACE;IACE,mBAAA;ECRF;EDUA;IACE,sBAAA;ECRF;EDUA;IACE,mBAAA;ECRF;EDUA;IACE,sBAAA;ECRF;EDUA;IACE,mBAAA;ECRF;AACF;ADYA;EACE;IACE,QAAA;ECVF;AACF;AAjBI;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;AAmBN;AAfE;EACE,2CAAA;EACA,YAAA;AAiBJ;AAbI;EACE,wBDlBG;ACiCT;AAXM;EACE,YAAA;AAaR;AAZQ;EACE,0BAAA;AAcV;AARE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,wBDpCK;AC8CT;AARI;EACE,0BAAA;AAUN","sourcesContent":["$darkblue-1: #2C2F48;\n\n$purple: rgba(115, 25, 117, 1);\n$red: rgba(224, 36, 36, 1);\n$grey: rgba(107, 114, 128, 1);\n$light-grey: rgba(181, 183, 192, 1);\n$light-purple: rgba(242, 239, 255, 1);\n$pre-black: rgba(49, 49, 49, 1);\n\n@keyframes transform-bounce {\n  0%{\n    transform: scale(0);\n  }\n  25%{\n    transform: scale(1.25);\n  }\n  50%{\n    transform: scale(1);\n  }\n  75%{\n    transform: scale(1.25);\n  }\n  100%{\n    transform: scale(1);\n  }\n}\n\n\n@keyframes transform-max-width {\n  0%{\n    width: 0;\n  }\n}\n","@import 'src/assets/styles/variables';\n\n.job-titles-table {\n  &-head {\n    &-cell {\n      text-align: left;\n      font-size: 12px;\n      font-weight: 400;\n      line-height: 18px;\n      color: rgba(107, 114, 128, 1);\n    }\n  }\n\n  th, td {\n    border-bottom: 1px solid rgba(220, 215, 254, 1);\n    height: 53px;\n  }\n\n  &-body & {\n    &-link-copy {\n      color: $purple;\n    }\n\n    &-link {\n      &-tag {\n        color: black;\n        &:hover{\n          text-decoration: underline;\n        }\n      }\n    }\n  }\n\n  &-copy-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 6px;\n    color: $purple;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
