import { useAppSelector } from '../../../redux/reduxHooks';
import useRedirect from '../../hooks/mainHooks';
import '../TextPage.scss';

export const VideoSuccess = () => {

    useRedirect(!localStorage.getItem("token"), "/sign-in");
    return (
        <main className={'text-page'}>
            <div className="text-page-content">
                <h1 className="text-page__title">
                    Этап 2 (видео интервью) пройден успешно
                </h1>
                <span className="text-page__text">
                    Видео-вопросы кандидата при приеме на работу успешно заполнены и отправлены
                </span>
                <a href="/test">
                    <button className='text-page__button'>
                        Перейти к психологическому тестированию
                    </button>
                </a>
            </div>
        </main>
    );
};