import { useLogCheck } from 'routes/hooks/mainHooks';
import './MetaTestAnswer.scss'
import { Button } from 'components/Button/Button';
import { useState } from 'react';

export const MetaTestAnswer = () => {
    const [question1, set1] = useState<string>('')
    const [question2, set2] = useState<string>('')
    const [question3, set3] = useState<string>('')
    useLogCheck()

    const checkAnswers = () => {
        return question1 && question2 && question3 !== ''
    }
    return (
        <main className='meta-test-answer'>
            <div className="meta-test-answer-block">
                <span className="meta-test-answer__number">Вопрос 1</span>
                <span className="meta-test-answer__question">Каков, на Ваш взгляд, род деятельности персонажа?</span>
                <textarea placeholder="Распишите здесь свой ответ на вопрос..." className="meta-test-answer__textarea"
                    name="" id="" onChange={(e) => set1(e.target.value)}>
                </textarea>
            </div>

            <div className="meta-test-answer-block">
                <span className="meta-test-answer__number">Вопрос 2</span>
                <span className="meta-test-answer__question">Какова направленность (позитивная либо негативная) и основные характеристики личности?</span>
                <textarea placeholder="Распишите здесь свой ответ на вопрос..." className="meta-test-answer__textarea"
                    name="" id="" onChange={(e) => set2(e.target.value)}>
                </textarea>
            </div>

            <div className="meta-test-answer-block">
                <span className="meta-test-answer__number">Вопрос 3</span>
                <span className="meta-test-answer__question">Ваши рекомендации изображенному на портрете?</span>
                <textarea placeholder="Распишите здесь свой ответ на вопрос..." className="meta-test-answer__textarea"
                    name="" id="" onChange={(e) => set3(e.target.value)}>
                </textarea>
            </div>
            { checkAnswers() && <a className={`meta-test-answer__link`} href="/allsuccess"><Button className={`meta-test-answer__button`}>Далее</Button></a>}
        </main>
    );
};