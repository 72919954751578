import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import './MentorsSlider.scss';
import {Mentor, MentorCard} from "../MentorCard/MentorCard";
import {Navigation} from 'swiper/modules';

interface MentorsSliderProps {
    mentors: Mentor[]
}


const swiperOptions = {
    spaceBetween: 50,
    slidesPerView: 3,
    navigation: {
        enabled: true,
        prevEl: ".mentors-slider__prev",
        nextEl: ".mentors-slider__next"
    },
    modules: [Navigation],
    onSlideChange: () => console.log('slide change'),
}

export const MentorsSlider = ({mentors}: MentorsSliderProps) => {
    return (
        <div className='mentors-slider'>
            <button className='mentors-slider__nav-btn mentors-slider__prev'>
                <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 2L2 10L10 18" stroke="white" strokeWidth="2.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </button>
            <Swiper {...swiperOptions}>
                {
                    mentors.map(mentor => {
                        return (
                            <SwiperSlide>
                                <MentorCard mentor={mentor}/>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <button className='mentors-slider__nav-btn mentors-slider__next'>
                <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2L10 10L2 18" stroke="white" strokeWidth="2.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </button>
        </div>
    );
};