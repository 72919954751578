// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--modal-container-z);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
}

.modal-content {
  display: contents;
}
.modal-content > * {
  animation-duration: 1s;
  animation-name: fadeInDown;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation: 2s fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iCAAA;EACA,kCAAA;UAAA,0BAAA;AACF;;AAEA;EACE,iBAAA;AACF;AACE;EACE,sBAAA;EACA,0BAAA;AACJ;;AAIA;EACE;IACE,UAAA;EADF;EAIA;IACE,UAAA;EAFF;AACF;AAKA;EACE,oBAAA;AAHF;;AAOA;EACE;IACE,UAAA;IACA,kCAAA;EAJF;EAOA;IACE,UAAA;IACA,+BAAA;EALF;AACF;AAQA;EACE,0BAAA;AANF","sourcesContent":[".modal-wrapper {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width:100%;\n  height:100%;\n  background: rgba(0, 0, 0, .1);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: var(--modal-container-z);\n  backdrop-filter: blur(1px);\n}\n\n.modal-content {\n  display: contents;\n\n  > * {\n    animation-duration: calc(1s);\n    animation-name: fadeInDown;\n  }\n}\n\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n}\n\n.fadeIn {\n  animation: 2s fadeIn;\n}\n\n\n@keyframes fadeInDown {\n  from {\n    opacity: 0;\n    transform: translate3d(0, -10%, 0);\n  }\n\n  to {\n    opacity: 1;\n    transform: translate3d(0, 0, 0);\n  }\n}\n\n.fadeInDown {\n  animation-name: fadeInDown;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
