// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentor-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 14px;
  border-radius: 10px;
  gap: 18px;
  background: white;
  overflow: hidden;
}
.mentor-card__image {
  aspect-ratio: 1/1;
  width: 224px;
  border-radius: 10px;
}
.mentor-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  max-width: 100%;
}
.mentor-card__name {
  max-width: 100%;
  color: black;
  font-weight: 300;
  font-size: 27px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}
.mentor-card__info {
  font-weight: 300;
  font-size: 14px;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  max-width: 100%;
}
.mentor-card__info span {
  color: rgb(115, 25, 117);
}
.mentor-card__button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 300;
  color: white;
  background: rgb(115, 25, 117);
  height: 34px;
}`, "",{"version":3,"sources":["webpack://./src/components/MentorCard/MentorCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EAEA,SAAA;EACA,iBAAA;EACA,gBAAA;AAAF;AAEE;EACE,iBAAA;EACA,YAAA;EACA,mBAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AADJ;AAKE;EACE,eAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,qBAAA;EACA,aAAA;AAHJ;AAME;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,qBAAA;EACA,aAAA;EACA,eAAA;AAJJ;AAMI;EACE,wBAAA;AAJN;AAQE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;AANJ","sourcesContent":[".mentor-card {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: stretch;\n  padding: 14px;\n  border-radius: 10px;\n  //width: 252px;\n  gap: 18px;\n  background: white;\n  overflow: hidden;\n\n  &__image {\n    aspect-ratio: 1/1;\n    width: 224px;\n    border-radius: 10px;\n  }\n\n  &-body {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 5px;\n    max-width: 100%;\n\n  }\n\n  &__name {\n    max-width: 100%;\n    color: black;\n    font-weight: 300;\n    font-size: 27px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    -webkit-line-clamp: 1;\n    line-clamp: 1;\n  }\n\n  &__info {\n    font-weight: 300;\n    font-size: 14px;\n    white-space: nowrap;\n    -webkit-line-clamp: 1;\n    line-clamp: 1;\n    max-width: 100%;\n\n    span {\n      color: rgba(115, 25, 117, 1);\n    }\n  }\n\n  &__button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 10px;\n    font-size: 15px;\n    font-weight: 300;\n    color: white;\n    background: rgba(115, 25, 117, 1);\n    height: 34px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
