import './PrivateOffice.scss'

export const PrivateOffice = () => {
    return <div className="private-office" >
        <h2 className="private-office-title">
            Личный кабинет
        </h2>
        <main className='content'>
            <div className="content-profile">
                <img className="content-profile-pic" src={require('./pic.png')} alt="" />
                <h3 className="content-profile-title">Короткова Георгия Хугаевна</h3>
                <p className="content-profile-info">Подтверждённая учётная запись</p>
            </div>
            <div className="content-stats">
                <div className="content-stats-box">
                    <h3>124</h3>
                    <p>Заявок рассмотренов<br></br><span>в период с 30.08 по 30.09</span></p>
                </div>
                <div className="content-stats-box">
                    <h3>124</h3>
                    <p>Заявок рассмотренов<br></br><span>в период с 30.08 по 30.09</span></p>
                </div>
                <div className="content-stats-box">
                    <h3>124</h3>
                    <p>Заявок рассмотренов<br></br><span>в период с 30.08 по 30.09</span></p>
                </div>
                <div className="content-stats-box">
                    <h3>124</h3>
                    <p>Заявок рассмотренов<br></br><span>в период с 30.08 по 30.09</span></p>
                </div>
            </div>
        </main>
    </div>
}