import { useEffect, useRef, useState } from 'react';
import './VideoACQ.scss';
import ReactPlayer from 'react-player';

export const VideoAcqPage = () => {

    const file = require('./hello.mp4');
    const progressRef = useRef<HTMLProgressElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)
    const [isPaused, setIsPaused] = useState<boolean>(true)
    const [currentTime, setCurrentTime] = useState<number>(0)
    const [videoDuration, setVideoDuration] = useState<number>(0);
    const skipButtonsRef = useRef<HTMLButtonElement>(null)

    /*   const togglePlayer = () => {
          const video = videoRef.current
          if (video) {
              if (isPaused) {
                  video.play()
                  setIsPaused(false)
              }
              else {
                  video.pause()
                  setIsPaused(true)
              }
          }
      }
  
      const formatTime = (timeInSeconds: number) => {
          const hours = Math.floor(timeInSeconds / 3600);
          const minutes = Math.floor((timeInSeconds % 3600) / 60);
          const seconds = Math.floor(timeInSeconds % 60);
  
          const formattedHours = String(hours).padStart(2, '0');
          const formattedMinutes = String(minutes).padStart(2, '0');
          const formattedSeconds = String(seconds).padStart(2, '0');
  
          return hours > 0 ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}` : `${formattedMinutes}:${formattedSeconds}`;
      };
  
      const handleProgressClick = (e: React.MouseEvent<HTMLProgressElement>) => {
          const progress = progressRef.current;
          const video = videoRef.current
          if (progress && video) 
              {
                  const clickPosition = e.clientX = progress.getBoundingClientRect().left;
                  const progressWidth = progress.clientWidth;
                  const clickPercentage = (clickPosition / progressWidth) * 100;
                  const newTime = (clickPercentage / 100) * video.duration;
                  video.currentTime = newTime;
                  setCurrentTime(newTime)
              }
      }
  
      useEffect(() => {
          const video = videoRef.current;
          const progress = progressRef.current;
  
          if (video && progress) {
              const updateProgress = () => {
                  const value = (video.currentTime / video.duration) * 100;
                  setCurrentTime(video.currentTime);
              };
  
              const handleLoadedMetadata = () => {
                  setVideoDuration(video.duration);
                  setCurrentTime(video.currentTime);
              };
  
              video.addEventListener('timeupdate', updateProgress);
              video.addEventListener('loadedmetadata', handleLoadedMetadata);
  
              return () => {
                  video.removeEventListener('timeupdate', updateProgress);
                  video.removeEventListener('loadedmetadata', handleLoadedMetadata);
              };
          }
      }, []); */

    return (
        <main className={'video-acq-page page'}>
            <h1 className={'video-acq-page__title'}>
                Знакомство со “SkillRadar”
            </h1>
            {/* <p className={'video-acq-page__text'}>
                Текст текст текст текст текст текст текст текст текст текст текст текст тексттекст текст текст текст
                текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст
                текст текст текст
            </p> */}

            <div >
                <ReactPlayer
                    controls
                    width="100%"
                    height="100%"
                    wrapperStyle={{
                        borderRadius: '40px',
                        overflow: 'hidden'
                    }} url={file}></ReactPlayer>

            </div>

            {/*  <div className="video-acq-page videoplayer">
                <video src={file} ref={videoRef} onClick={togglePlayer} />
                <div className="videoplayer-contents">
                    <p className="videoplayer-contents__company-name">
                        Компания Фелониуc Грю
                    </p>
                    <div className="videoplayer-contents-info">
                        <p className="videoplayer-contents__video-name">
                            Гадкий Я
                        </p>
                        <p className="videoplayer-contents__video-duration">
                            {formatTime(currentTime)}/{formatTime(videoDuration)}
                        </p>
                    </div>
                    <progress ref={progressRef} className="videoplayer-contents__progress" value={currentTime} max={videoDuration} />
                    <div className="videoplayer-contents-actions">
                        <div className="videoplayer-contents-dur-actions">
                            <button className="videoplayer-contents__play" onClick={togglePlayer}>
                                <svg width="16" height="20" viewBox="0 0 16 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.2857 1.11111C10.2857 0.497462 10.7974 0 11.4286 0H14.8571C15.4883 0 16 0.497461 16 1.11111V18.8889C16 19.5025 15.4883 20 14.8571 20H11.4286C10.7974 20 10.2857 19.5025 10.2857 18.8889V1.11111Z"
                                        fill="white" />
                                    <path
                                        d="M0 1.11111C0 0.497462 0.511675 0 1.14286 0H4.57143C5.20261 0 5.71429 0.497461 5.71429 1.11111V18.8889C5.71429 19.5025 5.20261 20 4.57143 20H1.14286C0.511675 20 0 19.5025 0 18.8889V1.11111Z"
                                        fill="white" />
                                </svg>
                            </button>
                            <button ref={skipButtonsRef} className="videoplayer-contents__prev" data-skip='-10'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.16803 8.60218L16.748 0.42814C18.1933 -0.382176 20 0.630719 20 2.25135V17.7486C20 19.3693 18.1933 20.3822 16.748 19.5719L2.16803 11.3978V8.60218Z"
                                        fill="white" />
                                    <path
                                        d="M2.16803 1.05263C2.16803 0.471279 1.6827 0 1.08401 0C0.485327 0 0 0.471279 0 1.05263V18.9474C0 19.5287 0.485327 20 1.08401 20C1.6827 20 2.16803 19.5287 2.16803 18.9474V1.05263Z"
                                        fill="white" />
                                </svg>

                            </button>
                            <button ref={skipButtonsRef} className="videoplayer-contents__next" data-skip='10'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.832 8.60218L3.25204 0.42814C1.80669 -0.382176 0 0.630719 0 2.25135V17.7486C0 19.3693 1.80669 20.3822 3.25204 19.5719L17.832 11.3978V8.60218Z"
                                        fill="white" />
                                    <path
                                        d="M17.832 1.05263C17.832 0.471279 18.3173 0 18.916 0C19.5147 0 20 0.471279 20 1.05263V18.9474C20 19.5287 19.5147 20 18.916 20C18.3173 20 17.832 19.5287 17.832 18.9474V1.05263Z"
                                        fill="white" />
                                </svg>
                            </button>
                        </div>
                        <div className="videoplayer-contents-other-actions">
                            <button className="videoplayer-contents__volume">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.8025 8C21.9331 9.31562 22 10.65 22 12C22 13.35 21.9331 14.6844 21.8025 16"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                    <path
                                        d="M17.8767 9C17.9583 9.9892 17.9999 10.9897 17.9999 12C17.9999 13.0103 17.9583 14.0108 17.8767 15"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                    <path
                                        d="M13 12C13 9.45813 12.7849 6.9701 12.373 4.55567C12.2494 3.83122 11.4028 3.51321 10.8175 3.95772L7.53627 6.44984C7.18835 6.71409 6.76348 6.85714 6.3266 6.85714H3C1.89543 6.85714 1 7.75257 1 8.85714V15.1429C1 16.2474 1.89543 17.1429 3 17.1429H6.3266C6.76348 17.1429 7.18835 17.2859 7.53627 17.5502L10.8175 20.0423C11.4027 20.4868 12.2494 20.1688 12.373 19.4443C12.7849 17.0299 13 14.5419 13 12Z"
                                        fill="white" stroke="white" strokeLinejoin="round" />
                                </svg>
                            </button>
                            <button className="videoplayer-contents__comment">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 11.4876 1.36093 12.891 2 14.1272L1 19L5.8728 18C7.10904 18.6391 8.51237 19 10 19Z"
                                        stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </button>
                            <button className="videoplayer-contents__fullscreen">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 9L4 6C4 4.89543 4.89543 4 6 4L9 4" stroke="white" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20 15V18C20 19.1046 19.1046 20 18 20H15" stroke="white" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15 4L18 4C19.1046 4 20 4.89543 20 6L20 9" stroke="white" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 20L6 20C4.89543 20 4 19.1046 4 18L4 15" stroke="white" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            <button className="videoplayer-contents__dots">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="3" r="2" fill="white" />
                                    <circle cx="10" cy="10" r="2" fill="white" />
                                    <circle cx="10" cy="17" r="2" fill="white" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

        </main>
    );
};