import { LinksHeader } from "components/LinksHeader/LinksHeader";
import './PersonPanel.scss'
import { useEffect, useState } from "react";
import { AnketaData } from "../AnketaData/AnketaData";
import { AnketaVideo } from "../AnketaVideo/AnketaVideo";
import { AdminPanel } from "../AdminPanel/AdminPanel";
import { useNavigate, useParams } from "react-router-dom";
import { changePosition, getActualSurvey, getSurveysThunk } from "../../../redux/adminReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { sendPosition } from "API/panelAPI";

export const PersonPanel = () => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const dispatch = useAppDispatch()
    const surveys = useAppSelector(state => state.adminReducer.surveys)
    const survey = useAppSelector(state => state.adminReducer.actualSurvey)
    const navigate = useNavigate()
    const { id } = useParams()
    useEffect(() => {
        dispatch(getSurveysThunk())
    }, [])

    useEffect(() => {
        if (id) {
            dispatch(getActualSurvey(id))
        }
    }, [surveys])

    const handlePositionChange = (position: number) => {
        if (id) {
            sendPosition(position, parseInt(id)).then(res => {navigate('/list-panel')})
        }
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(prevPage => {
            const newPage = prevPage + page;
            if (newPage === 0) navigate('/list-panel')
            return Math.max(0, Math.min(newPage, 3));
        });
    };
    return <main className="person-panel">
        {currentPage === 1 ? <LinksHeader handlePositionChange={handlePositionChange} onPageChange={handlePageChange} /> : currentPage === 2 ? <LinksHeader handlePositionChange={handlePositionChange} onPageChange={handlePageChange} title={'Данные видео-интервью'} /> : <LinksHeader handlePositionChange={handlePositionChange} onPageChange={handlePageChange} title={'Данные тестирования на психотип'} />}
        {currentPage === 1 && <AnketaData survey={survey} />}
        {currentPage === 2 && <AnketaVideo survey={survey} />}
        {currentPage === 3 && <AdminPanel survey={survey} />}
    </main>
}