// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anketa-video {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 40px;
  max-width: 1280px;
  color: white;
}
.anketa-video .full-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
}
.anketa-video .full-block .video-page-question {
  align-self: start;
  max-width: 100%;
  word-break: break-word;
}
.anketa-video .full-block .video {
  border: 1px solid rgb(59, 57, 57);
  border-radius: 40px;
  overflow: hidden;
  width: 811px;
  aspect-ratio: 811/466;
}
.anketa-video .full-block .video video {
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/routes/MainPanel/AnketaVideo/AnketaVideo.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AACJ;AACI;EACE,iBAAA;EACA,eAAA;EACA,sBAAA;AACN;AAEI;EACE,iCAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,qBAAA;AAAN;AAEM;EACE,iBAAA;AAAR","sourcesContent":[".anketa-video {\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n  gap: 40px;\n  max-width: 1280px;\n  color: white;\n\n  .full-block {\n    display: grid;\n    grid-template-columns: 1fr 2fr;\n    gap: 40px;\n\n    .video-page-question {\n      align-self: start;\n      max-width: 100%;\n      word-break: break-word;\n    }\n\n    .video {\n      border: 1px solid rgb(59, 57, 57);\n      border-radius: 40px;\n      overflow: hidden;\n      width: 811px;\n      aspect-ratio: 811/466;\n\n      video {\n        object-fit: cover;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
