// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: max-content;
  gap: 37px;
  margin: 0 auto;
}

.interview-block {
  position: relative;
  margin: 50px auto 0;
}

.interviewer-video {
  background: rgba(255, 255, 255, 0.5);
  width: 945px;
  height: 558px;
  border-radius: 15px;
}

.interviewer-name {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  padding: 0 29.5px;
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

.your-video {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: white;
  width: 297px;
  height: 182px;
  border-radius: 18px;
}

.interview-buttons {
  display: flex;
  gap: 17px;
}`, "",{"version":3,"sources":["webpack://./src/routes/Interview/Interview.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,oCAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,8BAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF","sourcesContent":[".interview {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  position: relative;\n  width: max-content;\n  gap: 37px;\n  margin: 0 auto;\n}\n\n.interview-block {\n  position: relative;\n  margin: 50px auto 0;\n}\n\n.interviewer-video {\n  background: rgba(255, 255, 255, 0.5);\n  width: 945px;\n  height: 558px;\n  border-radius: 15px;\n}\n\n.interviewer-name {\n  position: absolute;\n  top: 20px;\n  left: 20px;\n  height: 50px;\n  background: rgba(0, 0, 0, .3);\n  padding: 0 29.5px;\n  border-radius: 90px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px;\n  color: white;\n}\n\n.your-video {\n  position: absolute;\n  bottom: 10px;\n  right: 10px;\n  background: white;\n  width: 297px;\n  height: 182px;\n  border-radius: 18px;\n}\n\n.interview-buttons {\n  display: flex;\n  gap: 17px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
