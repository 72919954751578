import './HRList.scss';
import { FaCopy } from "react-icons/fa6";

export const HRList = () => {
    return <div className="hrlist">
        <header className='hrlist-header'>
            <h3 className='hrlist-title'>Должности 1-10 из 234</h3>
            <p>пагинация</p>
        </header>
        <table className='hrlist-table'>
            <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "50%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>Должность</th>
                    <th>Ссылка</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Frontend-разработчик</td>
                    <td>https://skill-radar.ru/sign-in/blablabla</td>
                    <td className="table-link">
                        <a href="@">Копировать ссылку <FaCopy className='table-link-faCopy'></FaCopy></a>
                    </td>
                </tr>
                <tr>
                    <td>Backend-разработчик</td>
                    <td>https://skill-radar.ru/sign-in/blablabla</td>
                    <td className="table-link">
                        <a href="@">Копировать ссылку <FaCopy className='table-link-faCopy'></FaCopy></a>
                    </td>
                </tr>
                <tr>
                    <td>Frontend-разработчик</td>
                    <td>https://skill-radar.ru/sign-in/blablabla</td>
                    <td className="table-link">
                        <a href="@">Копировать ссылку <FaCopy className='table-link-faCopy'></FaCopy></a>
                    </td>
                </tr>
                <tr>
                    <td>Бухгалтер</td>
                    <td>https://skill-radar.ru/sign-in/blablabla</td>
                    <td className="table-link">
                        <a href="@">Копировать ссылку <FaCopy className='table-link-faCopy'></FaCopy></a>
                    </td>
                </tr>
                <tr>
                    <td>Менеджер</td>
                    <td>https://skill-radar.ru/sign-in/blablabla</td>
                    <td className="table-link">
                        <a href="@">Копировать ссылку <FaCopy className='table-link-faCopy'></FaCopy></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}