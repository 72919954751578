// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workers-page {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 74px;
  padding: 40px 0;
}
.workers-page__title {
  font-size: 60px;
  font-weight: 400;
  line-height: 64px;
  text-align: center;
  color: white;
}
.workers-page__img {
  max-width: 60vw;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/routes/Workers/Workers.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;AAEJ;AAAE;EACE,eAAA;EACA,cAAA;AAEJ","sourcesContent":[".workers-page{\n  width:100%;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 74px;\n  padding: 40px 0;\n  &__title{\n    font-size: 60px;\n    font-weight: 400;\n    line-height: 64px;\n    text-align: center;\n    color: white;\n  }\n  &__img{\n    max-width: 60vw;\n    margin: 0 auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
