import './RoadmapStartPage.scss';
import { useState } from "react";
import { VideoAcqPage } from "../VideoACQ/VideoACQPage";
import { MentorsPage } from "../Mentors/MentorsPage";
import { MentorPage } from "../Mentor/MentorPage";
import { Workers } from '../Workers/Workers';
import { Plan } from '../Plan/Plan';
import { useNavigate } from 'react-router-dom';
import { useLogCheck } from 'routes/hooks/mainHooks';

export const RoadmapStartPage = () => {
    useLogCheck()
    const [step, setStep] = useState<number>(0);
    /* const [mentorSelected, setMentorSelected] = useState<boolean>(false); */
    return (
        <main className={'roadmap-start-page'}>
            {/* ПО ДИЗАЙНУ СНАЧАЛА ВЕТКА РАБОТНИКОВ ИДЁТ */}
            {step === 0 && <VideoAcqPage />}
            {step === 1 && <Workers />}
            {step === 2 && <Plan sidebar={false}/>}
            {step === 3 && /* !mentorSelected && */ <MentorsPage />}
            {/* {step === 3 && mentorSelected && <MentorPage />} */}
            {step < 3 && <button onClick={() => {
                setStep(step + 1)
            }
            } className="roadmap-start-page__next-btn">
                Далее
            </button>}
        </main>
    );
};