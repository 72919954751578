import { useEffect } from 'react';
import './Plan.scss';
import { useLogCheck, useSidebarDesperate } from '../hooks/mainHooks';

type Props =
    {
        sidebar?: boolean
    }

export const Plan = (props: Props) => {
    useSidebarDesperate(props.sidebar === true)
    useLogCheck()
    return (
        <main className={'plan-page'}>
            <h1 className={'plan-page__title'}>План стажировки</h1>
            <span className="plan-page__text">Ознакомьтесь с планом стажировки</span>

            <div className="plan-page-items">
                <div className="plan-page-item active">
                    <div className="plan-page-item__circle">
                        1
                    </div>
                    <span className="plan-page-item__name">Знакомство с компанией</span>
                    <span className="plan-page-item__text">
                        Введение в корпоративную культуру, правила внутреннего распорядка, информирование о социальных льготах, проведение инструктажа по технике безопасности, введение в подразделение, его функции, взаимосвязь с другими отделами, ознакомление с правилами делового и внерабочего общения.
                    </span>
                </div>
                <div className="plan-page-item">
                    <div className="plan-page-item__circle">
                        2
                    </div>
                    <span className="plan-page-item__name">Обучение</span>
                    <span className="plan-page-item__text">
                        Введение в должность: должностные обязанности, ожидаемые результаты, требования к качеству выполняемой работы. Обнаружение проблемных мест в профессиональной подготовке и создание условий для их устранения.
                    </span>
                </div>
                <div className="plan-page-item">
                    <div className="plan-page-item__circle">
                        3
                    </div>
                    <span className="plan-page-item__name">Контроль</span>
                    <span className="plan-page-item__text">
                        Предполагает прохождение стажером двух коротких опросов <br></br>
                        1. Оценка стажером прохождения своей адаптации.<br></br>
                        2. Оценка стажером социально-психологических качеств наставника.
                    </span>
                </div>
            </div>
        </main>
    );
};