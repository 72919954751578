import {FC, SVGProps, useMemo} from 'react';


export interface IChevronIconProps extends SVGProps<SVGSVGElement> {
    direction: 'top' | 'right' | 'bottom' | 'left';
}


export const ChevronIcon: FC<IChevronIconProps> = ({direction = 'left', ...restProps}) => {
    const rotateDegree = useMemo(() => {
        switch (direction) {
            case 'top': {
                return 90;
            }
            case 'right': {
                return 180;
            }
            case 'bottom': {
                return -90;
            }
            case 'left': {
                return 0;
            }
        }
    }, [direction])
    return (
        <svg style={{transform: `rotate(${rotateDegree}deg)`, transformOrigin: 'center'}} width="20" height="21"
             viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.707 5.79279C12.8945 5.98031 12.9998 6.23462 12.9998 6.49979C12.9998 6.76495 12.8945 7.01926 12.707 7.20679L9.41403 10.4998L12.707 13.7928C12.8892 13.9814 12.99 14.234 12.9877 14.4962C12.9854 14.7584 12.8803 15.0092 12.6948 15.1946C12.5094 15.38 12.2586 15.4852 11.9964 15.4875C11.7342 15.4897 11.4816 15.3889 11.293 15.2068L7.29303 11.2068C7.10556 11.0193 7.00024 10.765 7.00024 10.4998C7.00024 10.2346 7.10556 9.98031 7.29303 9.79279L11.293 5.79279C11.4806 5.60532 11.7349 5.5 12 5.5C12.2652 5.5 12.5195 5.60532 12.707 5.79279Z"
                  fill="currentColor"/>
        </svg>


    );
};
