import { combineReducers, configureStore } from "@reduxjs/toolkit";
import surveyReducer from "./surveyReducer";
import psychoReducer from "./psychoReducer";
import authReducer from "./authReducer";
import videoReducer from "./videoReducer";
import roadmapReducer from "./roadmapReducer";
import adminReducer from "./adminReducer";


export const rootReducer = combineReducers({
    psychoReducer: psychoReducer,
    authReducer: authReducer,
    videoReducer: videoReducer,
    surveyReducer: surveyReducer,
    roadmapReducer: roadmapReducer,
    adminReducer: adminReducer
})

export type RootReducer = typeof rootReducer;
export const store = configureStore({
    reducer: rootReducer,
    devTools: true
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStateType = ReturnType<RootReducer>;
export type AppDispatch = typeof store.dispatch;

// @ts-ignore
window.store = store;
