import React from 'react';
import './DefaultLayout.scss';
import {Outlet} from "react-router-dom";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";

const DefaultLayout = () => {

  
    return (
        <div className="default-layout">
            <Header />
            <div className="default-layout__body">
                {/*<Sidebar className="default-layout__sidebar"/>*/}
                <main className="default-layout__page-content">
                <Outlet/>
                </main>
            </div>
        </div>
    );
};

export default DefaultLayout;