// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-titles-page {
  background: white;
  padding: 32px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 95%;
}
.job-titles-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-titles-page-title {
  color: black;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/routes/JobTitlesPage/JobTitlePage.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,UAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAEE;EACE,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".job-titles-page {\n  background: white;\n  padding: 32px;\n  border-radius: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 28px;\n  width: 95%;\n\n  &-header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  &-title {\n    color: black;\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 24px;\n    text-align: left;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
