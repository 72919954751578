import { useLogCheck, useSidebarDesperate } from '../hooks/mainHooks';
import './ChatsPage.scss'

export const ChatsPage = () => {
    useLogCheck()
    useSidebarDesperate(true)
    return (
        <main className="chats-page">
            <h1 className="chats-page__title">Чаты</h1>
            <div className="chats-page-body">
                <a href='/chat' className="chats-page-item">
                    <div className="chats-page-item-initials">
                        ИБ
                    </div>
                    <span className="chats-page-item-content">
                        <p className="chats-page-item__name">Илона Бекоева</p>
                        <p className="chats-page-item__status">Онлайн</p>
                    </span>
                </a>
                <a href='/chat' className="chats-page-item">
                    <div className="chats-page-item-initials">
                        МЗ
                    </div>
                    <span className="chats-page-item-content">
                        <p className="chats-page-item__name">Мария Зайцева</p>
                        <p className="chats-page-item__status">Онлайн</p>
                    </span>
                </a>
                <a href='/chat' className="chats-page-item">
                    <div className="chats-page-item-initials">
                        ИМ
                    </div>
                    <span className="chats-page-item-content">
                        <p className="chats-page-item__name">Ирина Миронова</p>
                        <p className="chats-page-item__status">Онлайн</p>
                    </span>
                </a>
            </div>
        </main>
    );
};