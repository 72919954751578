
import './ListPanel.scss';
import { useLogCheck, useSidebarDesperate } from "routes/hooks/mainHooks";
import { FaSearch } from 'react-icons/fa'
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { getSurveysThunk } from "../../../redux/adminReducer";
import { useNavigate } from "react-router-dom";
export const ListPanel = () => {
    useLogCheck()
    const dispatch = useAppDispatch()
    const surveys = useAppSelector(state => state.adminReducer.surveys)
    const [filter, setFilter] = useState("");
    const filteredSurveys = surveys ? surveys.filter(survey => filter === "" || survey.role_id.toString() === filter) : [];
    const navigate = useNavigate()
    const formatDate = (date: string) => {
        const first = date.slice(0, 10).split('-')
        return first[2] + '.' + first[1] + '.' + first[0]
    }

    const formatExperience = (years1: string): string => {
        let years = parseInt(years1)
        if (years === 1) {
            return `${years} год`;
        } else if (years > 1 && years < 5) {
            return `${years} года`;
        } else if (years % 10 === 1 && (years < 11 || years > 14)) {
            return `${years} год`;
        } else {
            return `${years} лет`;
        }
    };

    useEffect(() => {
        dispatch(getSurveysThunk())
    }, [])
    useSidebarDesperate(false)
    return <main className={'panel'}>
        <div className={'panel__header'}>
            <h1>Заявки кандидатов</h1>
            <div className="searchfilter">
                <div className={'search-bar'}>
                    <label htmlFor="search"><FaSearch className="search-bar__icon" /></label>
                    <input id="search" type="text" className="search-bar__input" placeholder="Поиск" />
                    {/* <HeaderInput></HeaderInput> */}
                </div>
                <select className="filter" onChange={(e) => setFilter(e.target.value)} name="filter" id="filter" >
                    <option selected value="">все анкеты</option>
                    <option value="3">одобрено</option>
                    <option value="2">отказано</option>
                    <option value="1">на рассмотрении</option>
                </select>
            </div>

        </div>
        <section className="applications">
            
            {filteredSurveys.length > 0 ? filteredSurveys.map(s => {
                return <div className="applications__app">
                    <div className="app-header">
                        <h2 className="app-name">{s.last_name} {s.first_name} {s.middle_name}</h2>
                        <div className="app-status">
                            <h3>Статус</h3>
                            <p>{s.role_id === 1 && 'на рассмотрении' || s.role_id === 2 && 'отказано' || s.role_id === 3 && 'одобрено'}</p>
                        </div>
                    </div>
                    <div className="app-bottom">
                        <div className="app-bottom__left">
                            <div>
                                <h3>Дата подачи заявки</h3>
                                <p>{formatDate(s.creation_date)}</p>
                            </div>
                            <div>
                                <h3>Уровень</h3>
                                <p>Middle</p>
                            </div>
                            <div>
                                <h3>Опыт работы</h3>
                                <p>{formatExperience(s.year_work_experience)}</p>
                            </div>
                        </div>
                        <div className="app-bottom__right">
                            <div>
                                <button onClick={() => navigate(`/person-panel/${s.Id}`)}>Перейти к анкете</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            ) : <div className={'empty-surveys'}>
                <div>В данный момент анкет нет</div>
            </div>}

        </section>
    </main>
}