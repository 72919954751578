import {FC, SVGProps} from 'react';

export const StopCallIcon: FC<SVGProps<SVGSVGElement>> = ({...restProps}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.8337 13.9998C19.746 6.91217 8.25465 6.91217 1.16699 13.9998" stroke="currentColor" strokeWidth="2.25"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M26.8337 13.9999L24.4732 16.3604C23.5619 17.2717 22.0846 17.2717 21.1733 16.3604L19.6096 14.7967C18.9964 14.1835 18.7738 13.2808 19.0317 12.4529L19.8823 9.72217M1.16699 13.9999L3.52749 16.3604C4.43872 17.2717 5.9161 17.2717 6.82733 16.3604L8.39108 14.7967C9.00427 14.1835 9.22682 13.2808 8.96893 12.4529L8.11838 9.72217"
                stroke="currentColor" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
