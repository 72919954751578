import React, { ChangeEvent, useEffect, useState } from "react";
import "./Questionary.scss";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import { saveSurvey, SurveyType } from "../../redux/surveyReducer";
import { sendSurveyAPI } from "../../API/surveyAPI";
import { useLogCheck } from "../hooks/mainHooks";

export type formData = {
  LastName: string;
  FirstName: string;
  MiddleName: string;
  BirthDate: string;
  City: string;
  Education: string;
  Email: string;
  ReasonDismissal: string;
  YearWorkExperience: number;
  [key: string]: any;
};

const Questionary = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const surveyValues = useAppSelector(state => state.surveyReducer.survey)
  const [isFirstStep, setIsFirstStep] = useState<boolean>(true);
  const [FFF, setFFF] = useState(false);
  const [resume, setResume] = useState<File | null>(null)
  const [resumeName, setResumeName] = useState<string>('')
  useLogCheck()



  useEffect(() => {
    if (
      surveyValues.LastName &&
      surveyValues.FirstName &&
      surveyValues.MiddleName &&
      surveyValues.Email &&
      surveyValues.BirthDate &&
      surveyValues.City
    ) {
      setFFF(true);
    } else {
      setFFF(false);
    }
  }, [surveyValues]);

  const resumeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setResume(files[0]);
      setResumeName(files[0].name)
    }
  }

  return (
    <main className={"questionary"}>
      <h1 className="questionary__title">Заполните анкету</h1>
      <small className="questionary__small">
        SkillRadar станет Вашим персональным проводником в профессиональную среду! Мы ценим стремление к карьерному росту и развитию и готовы помочь Вам в этом.
        <br />
        <br />
        Просим Вас перейти к заполнению анкеты, для того чтобы мы могли оценить Ваши навыки, таланты, опыт и потенциал
      </small>
      {
        <Formik
          initialValues={surveyValues as formData}
          validate={(values: formData) => {
            const errors: Partial<formData> = {};
            if (isFirstStep) {
              if (
                !values.LastName ||
                !values.FirstName ||
                !values.MiddleName ||
                !values.Email ||
                !values.BirthDate ||
                !values.City
              ) {
                setFFF(false);
              }
              else if (values.BirthDate.length !== 10) {
                setFFF(false);
              }

              else {
                setFFF(true);
              }

              if (!values.Email) {
                errors.Email = "Required";
                setFFF(false);
              } else if (
                !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values.Email)
              ) {
                errors.Email = "Invalid email address";
                setFFF(false);
              }
            }



            return errors;
          }}
          onSubmit={(values: formData, { setSubmitting }: FormikHelpers<formData>) => {
            let surveyData: SurveyType = values;
            setSubmitting(false);
            if (resume !== null) {
              debugger
              dispatch(saveSurvey(surveyData));
              sendSurveyAPI(values, resume).then((res) => {
                console.log(res);
                navigate("/questionarysuccess");
              }).catch(err => { console.log(err) }).finally(() => {
                navigate("/questionarysuccess");
              })
            }
          }}
        >
          {(formikProps: FormikHelpers<formData>) => (
            <Form className="questionary-form">
              {isFirstStep && (
                <>
                  <label
                    className="questionary-form-input-block"
                    htmlFor="surname"
                  >
                    <small>Фамилия: *</small>
                    <Field
                      name={"LastName"}
                      id={"surname"}
                      placeholder="Фамилия"
                      type="text"
                    />
                  </label>
                  <label
                    className="questionary-form-input-block"
                    htmlFor="name"
                  >
                    <small>Имя: *</small>
                    <Field
                      name={"FirstName"}
                      id="name"
                      placeholder="Имя"
                      type="text"
                    />
                  </label>
                  <label
                    className="questionary-form-input-block"
                    htmlFor="father_name"
                  >
                    <small>Отчество: *</small>
                    <Field
                      name={"MiddleName"}
                      id="father_name"
                      placeholder="Отчество"
                      type="text"
                    />
                  </label>
                  <label
                    className="questionary-form-input-block"
                    htmlFor="email"
                  >
                    <small>E-mail: *</small>
                    <Field
                      name={"Email"}
                      id="email"
                      placeholder="E-mail"
                      type="email"
                    />
                  </label>
                  <label
                    className="questionary-form-input-block"
                    htmlFor="birthday"
                  >
                    <small>Дата рождения: *</small>
                    <Field
                      name="BirthDate"
                      id="birthday"
                      placeholder="дд.мм.гггг"
                      type="text"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        let { value } = e.target;
                        value = value.replace(/\D/g, "");
                        if (value.length > 2) {
                          value = value.substring(0, 2) + "." + value.substring(2);
                        }
                        if (value.length > 5) {
                          value = value.substring(0, 5) + "." + value.substring(5);
                        }
                        value = value.slice(0, 10);
                        formikProps.setFieldValue("BirthDate", value);
                      }}
                    />
                    <small>Место проживания: *</small>
                    <Field
                      name={"City"}
                      id="address"
                      placeholder="Адрес"
                      type="datetime"
                    />
                  </label>
                  <button
                    type="button"
                    className="questionary-form__submit"
                    onClick={() => {
                      if (FFF) setIsFirstStep(false);
                      else alert("Заполните все поля");
                    }}
                  >
                    Далее
                  </button>
                </>
              )}

              {!isFirstStep && (
                <>
                  <label
                    className="questionary-form-input-block"
                    htmlFor="education"
                  >
                    <small>Образование: *</small>
                    <Field
                      required
                      name={"Education"}
                      id="education"
                      placeholder="Образование"
                      type="text"
                    />
                  </label>

                  <label
                    className="questionary-form-input-block"
                    htmlFor="experience"
                  >
                    <small>Опыт работы: *</small>
                    <Field
                      required
                      name={"YearWorkExperience"}
                      id="experience"
                      placeholder="Опыт работы"
                      type="number"
                      pattern="[0-9]*"
                    />
                  </label>
                  <label
                    className="questionary-form-input-block"
                    htmlFor="leave-reason"
                  >
                    <small>Причина ухода с предыдущей работы: *</small>
                    <Field
                      required
                      name={'ReasonDismissal'}
                      id="leave-reason"
                      placeholder="Причина ухода с предыдущей работы...."
                      type="text"
                    />
                  </label>

                  <label
                    className={'questionary-form-input-block'}
                    htmlFor="resume"
                  >
                    <small>Загрузите резюме (*pdf, *png)</small>
                    <input
                      required
                      type="file"
                      id="resume"
                      className={resumeName !== '' ? 'checked' : ''}
                      onChange={resumeHandler}
                    />
                  </label>

                  <button type='submit' className="questionary-form__submit">
                    Отправить
                  </button>
                </>
              )}
            </Form>
          )}
        </Formik>
      }
    </main >
  );
};
export default Questionary;
