
import './AnketaVideo.scss';
import ReactPlayer from "react-player";
import { AdminSurveyType } from "redux/adminReducer";
import { useEffect, useState } from "react";
import { getAnswers } from 'API/panelAPI';


type VideoAnswerType = {
    question: string
    answer_path: string
}

export const AnketaVideo = ({ survey }: { survey: AdminSurveyType }) => {
    const file = require('../../VideoACQ/test.mp4');
    const [answers, setAnswers] = useState<VideoAnswerType[]>([])
    useEffect(() => {
        getAnswers(survey.Id).then(res => {
            console.log(res.data); setAnswers(res.data)
        })
    }, [])
    return <main className="anketa-video">
        {answers.map((a, index) => {
            return <div className="full-block">
                <div className="video-page-question">
                    <h3 className="video-page-question__title">Вопрос {index + 1}: {a && a.question}</h3>
                    <span className="video-page-question__content">
                        Ответ на вопрос может включать в себя следующую информацию: с какой целью Вы ищете работу, что Вы умеете, где Вы обучались и работали, почему Вас заинтересовала данная вакансия, почему Вы считаете, что подходите для работы в компании.
                    </span>
                </div>
                <div className="video">
                    <ReactPlayer
                        className="video-player"
                        style={{}}
                        controls
                        width='100%'
                        height='100%'
                        url={a && `https://s3.timeweb.com/f457174f-5999b11e-29c5-4b55-a452-33232e2640bb/${a.answer_path}`}></ReactPlayer>
                </div>
            </div>
        }
        )}
    </main>
}
