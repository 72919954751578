import { instance } from "./main";

export const sendNumberAPI = (phonenumber: string) => {
  return instance.post("/candidate/registration", { phonenumber });
};

export const sendCodeAPI = (phonenumber: string, password: string) => {
  return instance.post("/candidate/auth", { phonenumber, password });
};

export const updatePassword = (phonenumber: string) => 
  {
    return instance.post("/candidate/newpass", {phonenumber})
  }
