import './Directions.scss';
import { animate, stagger } from "framer-motion";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useLogCheck } from 'routes/hooks/mainHooks';

export const Directions = () => {
    useLogCheck()
    useEffect(() => {
        animate(".directions-item", { opacity: 1, transform: 'translateY(0px)' }, { delay: stagger(0.05) })
    }, []);
    const navigate = useNavigate()
    return (
        <main className={'directions'}>
            <table className={'directions-table lato-14-400'}>
                <thead className={'directions-table-head'}>
                    <tr>
                        <th className='lato-14-500'>Product Name</th>
                        <th className='lato-14-500'>На рассмотрении</th>
                        <th className='lato-14-500'>Одобрено</th>
                        <th className='lato-14-500'>Отказано</th>
                    </tr>
                </thead>
                <tbody>
                    {Array(10).fill(null).map(() => {
                        return (<tr onClick={() => navigate('/admin/list-panel')} className={'directions-item'}>
                            <td>
                                <div className='cell-product-name'>
                                    <span className="cell-product-name-value lato-22-500">Программист</span>
                                    <span className="cell-product-name-small lato-14-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                                </div>
                            </td>
                            <td>
                                <div className='cell-on-review'>
                                    32 кандидата
                                </div>
                            </td>
                            <td>
                                <div className='cell-accepted'>
                                    3 кандидата
                                </div>
                            </td>
                            <td>
                                <div className='cell-rejected'>
                                    3 кандидата
                                </div>
                            </td>
                        </tr>)
                    })}
                </tbody>
            </table>
        </main>
    );
};