// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roadmap-layout {
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
  padding: 20px;
}
.roadmap-layout__body {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  height: 100%;
}
.roadmap-layout__sidebar {
  flex-shrink: 0;
  height: 100%;
  position: sticky;
  top: 20px;
}
.roadmap-layout__page-content {
  flex-grow: 1;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layouts/RoadmapLayout/RoadmapLayout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,SAAA;EACA,YAAA;AAEJ;AAAE;EACE,cAAA;EAEA,YAAA;EACA,gBAAA;EACA,SAAA;AACJ;AACE;EACE,YAAA;EACA,YAAA;AACJ","sourcesContent":[".roadmap-layout{\n  display: flex;\n  align-items: flex-start;\n  min-height: 100vh;\n  padding: 20px;\n  &__body{\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-start;\n    gap: 15px;\n    height: 100%;\n  }\n  &__sidebar{\n    flex-shrink: 0;\n    //flex-grow: 1;\n    height:100%;\n    position: sticky;\n    top: 20px;\n  }\n  &__page-content{\n    flex-grow: 1;\n    height:100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
