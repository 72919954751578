import {FC} from 'react';
import './Interview.scss';
import {RoundButton} from "../../components/RoundButton/RoundButton";
import {MicrophoneIcon} from "../../assets/icons/MicrophoneIcon";
import {CameraIcon} from "../../assets/icons/CameraIcon";
import {StopCallIcon} from "../../assets/icons/StopCallIcon";

export interface IInterviewProps {

}

export const Interview: FC<IInterviewProps> = () => {
    return (
        <main className={'interview page'}>
            <div className="interview-block">
                <video className={'interviewer-video'}
                       poster={'https://www.google.com/url?sa=i&url=https%3A%2F%2Fopen.spotify.com%2Fartist%2F0hmUwzWBrPHpGWGrYH05c3&psig=AOvVaw0OJBUmC_6_Fmw45FiGO-M-&ust=1727117183292000&source=images&cd=vfe&opi=89978449&ved=0CBQQjRxqFwoTCOCN-Ieb14gDFQAAAAAdAAAAABAE'}
                />
                <div className={'interviewer-name lato-20-500'}>Наставник</div>
                <div className="your-video"></div>
            </div>
            <div className="interview-buttons">
                <RoundButton variant={'blue'}><MicrophoneIcon/></RoundButton>
                <RoundButton variant={'blue'}><CameraIcon/></RoundButton>
                <RoundButton variant={'red'}><StopCallIcon/></RoundButton>
            </div>
        </main>
    );
};


