import { useSidebarDesperate } from 'routes/hooks/mainHooks';
import './Intership.scss';
import { useNavigate } from 'react-router-dom';

export const Intership = () => {
    useSidebarDesperate(true)
    const navigate = useNavigate()
    return (
        <main className='intership-page'>
            <header className="intership-page-header">
                <div className="intership-page-header-body">
                    <div className="intership-page-header-content">
                        <h3 className="intership-page__title">
                            Стажировка
                        </h3>
                        <span className="intership-page__progress-text">Прогресс по курсу: 60%</span>
                    </div>
                    <div className="intership-page-header-grand">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.1451 10.2583C10.8708 10.2583 10.648 10.0363 10.6472 9.76168C10.6463 9.48672 10.8686 9.26299 11.1437 9.26221C12.3948 9.25832 13.5851 8.50294 14.4952 7.13513C15.3687 5.82231 15.8819 4.10548 15.9601 2.25635H13.7664C13.4913 2.25635 13.2683 2.03339 13.2683 1.7583C13.2683 1.48321 13.4913 1.26025 13.7664 1.26025H16.4687C16.7438 1.26025 16.9667 1.48321 16.9667 1.7583C16.9667 3.98991 16.3835 6.09533 15.3246 7.687C14.2245 9.34016 12.7408 10.2534 11.1468 10.2583C11.1463 10.2583 11.1458 10.2583 11.1451 10.2583Z"
                                fill="#FFB023"/>
                            <path
                                d="M5.78837 10.2583C5.78772 10.2583 5.78733 10.2583 5.78681 10.2583C4.1928 10.2534 2.70904 9.34016 1.60906 7.687C0.550057 6.09533 -0.0332031 3.98991 -0.0332031 1.7583C-0.0332031 1.48321 0.189751 1.26025 0.464844 1.26025H3.16714C3.44223 1.26025 3.66519 1.48321 3.66519 1.7583C3.66519 2.03339 3.44223 2.25635 3.16714 2.25635H0.973397C1.05161 4.10548 1.56483 5.82231 2.43836 7.13513C3.34846 8.50294 4.53871 9.25832 5.7898 9.26221C6.06489 9.26299 6.2872 9.48672 6.28629 9.76168C6.28551 10.0363 6.06269 10.2582 5.78837 10.2583Z"
                                fill="#FFB023"/>
                            <path
                                d="M13.8014 0.0332031H3.13208C2.85712 0.0332031 2.63403 0.256157 2.63403 0.53125C2.63403 3.45689 3.2077 6.21534 4.24918 8.29832C4.91649 9.6328 5.73256 10.6015 6.63501 11.1561V13.8678C6.63501 14.1428 6.85809 14.3658 7.13306 14.3658H9.80046C10.0756 14.3658 10.2985 14.1428 10.2985 13.8678V11.1561C11.201 10.6017 12.0172 9.6328 12.6843 8.29832C13.7258 6.21534 14.2995 3.45689 14.2995 0.53125C14.2995 0.256157 14.0765 0.0332031 13.8014 0.0332031Z"
                                fill="#FFB023"/>
                            <path
                                d="M11.1341 13.3696H5.79952C4.78955 13.3696 3.96777 14.1914 3.96777 15.2014V16.5351C3.96777 16.8101 4.19073 17.033 4.46582 17.033H12.4678C12.7429 17.033 12.9658 16.8101 12.9658 16.5351V15.2014C12.9658 14.1914 12.1442 13.3696 11.1341 13.3696Z"
                                fill="#FF8E1D"/>
                            <path
                                d="M13.0012 17.0331H3.93237C3.65741 17.0331 3.43433 16.8101 3.43433 16.5352C3.43433 16.2601 3.65741 16.0371 3.93237 16.0371H13.0012C13.2763 16.0371 13.4993 16.2601 13.4993 16.5352C13.4993 16.8101 13.2763 17.0331 13.0012 17.0331Z"
                                fill="#FF8E1D"/>
                        </svg>
                    </div>

                </div>
                <div className="intership-page-progress"></div>
            </header>
            <div className="intership-page-content">
                <div className="intership-page-item">
                    <span className="intership-page-item__name">Уровень 1</span>
                    <button className="intership-page-item__button" onClick={() => { navigate('/intership') }}>Начать</button>
                    <div className="intership-page-item-stepper">
                        01/30
                    </div>

                    <svg className="intership-page-item-venom" width="142" height="71" viewBox="0 0 142 71" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M141.702 71H3.76044C-0.304711 56.2073 -3.81689 37.6794 8.70194 35.0555C19.6101 32.7692 25.9684 36.8389 29.8657 39.3334C31.94 40.661 33.3172 41.5425 34.3121 40.7819C34.9861 40.7258 35.9296 38.6262 34.3121 30.6766C34.2859 30.5478 34.2592 30.4169 34.2321 30.2838C32.2743 20.6732 28.0629 0 39.9565 0C40.6091 0 41.3101 0.062226 42.0626 0.191919C50.3618 1.62228 53.6382 7.2638 56.1116 11.5226C58.0067 14.7859 59.4305 17.2374 62.2811 16.3606C65.0769 15.5007 66.5916 12.2628 68.0841 9.07245C70.0995 4.76427 72.0743 0.542901 77.1081 2.38145C82.3168 4.28389 83.8334 11.7819 84.9857 17.4791C85.7717 21.365 86.3883 24.4131 87.8913 24.2765C89.2623 24.1519 90.472 21.4467 91.921 18.2062C94.3896 12.6856 97.5529 5.61151 103.392 7.09731C108.293 8.34437 110.131 18.0713 111.871 27.2835C113.422 35.4897 114.895 43.2874 118.388 44.3189C121.425 45.2159 123.67 44.4451 125.68 43.7552C128.576 42.7611 130.982 41.935 134.562 46.5084C137.338 50.0538 140.714 60.2297 141.702 71Z"
                              fill="#874088"/>
                    </svg>

                </div>
                <div className="intership-page-item">
                    <span className="intership-page-item__name">Уровень 2</span>
                    <button className="intership-page-item__button">Начать</button>
                    <div className="intership-page-item-stepper">
                        01/30
                    </div>

                    <svg className="intership-page-item-venom" width="142" height="71" viewBox="0 0 142 71" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M141.702 71H3.76044C-0.304711 56.2073 -3.81689 37.6794 8.70194 35.0555C19.6101 32.7692 25.9684 36.8389 29.8657 39.3334C31.94 40.661 33.3172 41.5425 34.3121 40.7819C34.9861 40.7258 35.9296 38.6262 34.3121 30.6766C34.2859 30.5478 34.2592 30.4169 34.2321 30.2838C32.2743 20.6732 28.0629 0 39.9565 0C40.6091 0 41.3101 0.062226 42.0626 0.191919C50.3618 1.62228 53.6382 7.2638 56.1116 11.5226C58.0067 14.7859 59.4305 17.2374 62.2811 16.3606C65.0769 15.5007 66.5916 12.2628 68.0841 9.07245C70.0995 4.76427 72.0743 0.542901 77.1081 2.38145C82.3168 4.28389 83.8334 11.7819 84.9857 17.4791C85.7717 21.365 86.3883 24.4131 87.8913 24.2765C89.2623 24.1519 90.472 21.4467 91.921 18.2062C94.3896 12.6856 97.5529 5.61151 103.392 7.09731C108.293 8.34437 110.131 18.0713 111.871 27.2835C113.422 35.4897 114.895 43.2874 118.388 44.3189C121.425 45.2159 123.67 44.4451 125.68 43.7552C128.576 42.7611 130.982 41.935 134.562 46.5084C137.338 50.0538 140.714 60.2297 141.702 71Z"
                              fill="#874088"/>
                    </svg>

                </div>
                <div className="intership-page-item">
                    <span className="intership-page-item__name">Уровень 3</span>
                    <button className="intership-page-item__button">Начать</button>
                    <div className="intership-page-item-stepper">
                        01/30
                    </div>

                    <svg className="intership-page-item-venom" width="142" height="71" viewBox="0 0 142 71" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M141.702 71H3.76044C-0.304711 56.2073 -3.81689 37.6794 8.70194 35.0555C19.6101 32.7692 25.9684 36.8389 29.8657 39.3334C31.94 40.661 33.3172 41.5425 34.3121 40.7819C34.9861 40.7258 35.9296 38.6262 34.3121 30.6766C34.2859 30.5478 34.2592 30.4169 34.2321 30.2838C32.2743 20.6732 28.0629 0 39.9565 0C40.6091 0 41.3101 0.062226 42.0626 0.191919C50.3618 1.62228 53.6382 7.2638 56.1116 11.5226C58.0067 14.7859 59.4305 17.2374 62.2811 16.3606C65.0769 15.5007 66.5916 12.2628 68.0841 9.07245C70.0995 4.76427 72.0743 0.542901 77.1081 2.38145C82.3168 4.28389 83.8334 11.7819 84.9857 17.4791C85.7717 21.365 86.3883 24.4131 87.8913 24.2765C89.2623 24.1519 90.472 21.4467 91.921 18.2062C94.3896 12.6856 97.5529 5.61151 103.392 7.09731C108.293 8.34437 110.131 18.0713 111.871 27.2835C113.422 35.4897 114.895 43.2874 118.388 44.3189C121.425 45.2159 123.67 44.4451 125.68 43.7552C128.576 42.7611 130.982 41.935 134.562 46.5084C137.338 50.0538 140.714 60.2297 141.702 71Z"
                              fill="#874088"/>
                    </svg>

                </div>
                <div className="intership-page-item">
                    <span className="intership-page-item__name">Уровень 4</span>
                    <button className="intership-page-item__button">Начать</button>
                    <div className="intership-page-item-stepper">
                        01/30
                    </div>

                    <svg className="intership-page-item-venom" width="142" height="71" viewBox="0 0 142 71" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M141.702 71H3.76044C-0.304711 56.2073 -3.81689 37.6794 8.70194 35.0555C19.6101 32.7692 25.9684 36.8389 29.8657 39.3334C31.94 40.661 33.3172 41.5425 34.3121 40.7819C34.9861 40.7258 35.9296 38.6262 34.3121 30.6766C34.2859 30.5478 34.2592 30.4169 34.2321 30.2838C32.2743 20.6732 28.0629 0 39.9565 0C40.6091 0 41.3101 0.062226 42.0626 0.191919C50.3618 1.62228 53.6382 7.2638 56.1116 11.5226C58.0067 14.7859 59.4305 17.2374 62.2811 16.3606C65.0769 15.5007 66.5916 12.2628 68.0841 9.07245C70.0995 4.76427 72.0743 0.542901 77.1081 2.38145C82.3168 4.28389 83.8334 11.7819 84.9857 17.4791C85.7717 21.365 86.3883 24.4131 87.8913 24.2765C89.2623 24.1519 90.472 21.4467 91.921 18.2062C94.3896 12.6856 97.5529 5.61151 103.392 7.09731C108.293 8.34437 110.131 18.0713 111.871 27.2835C113.422 35.4897 114.895 43.2874 118.388 44.3189C121.425 45.2159 123.67 44.4451 125.68 43.7552C128.576 42.7611 130.982 41.935 134.562 46.5084C137.338 50.0538 140.714 60.2297 141.702 71Z"
                              fill="#874088"/>
                    </svg>

                </div>
                <div className="intership-page-item">
                    <span className="intership-page-item__name">Уровень 5</span>
                    <button className="intership-page-item__button">Начать</button>
                    <div className="intership-page-item-stepper">
                        01/30
                    </div>

                    <svg className="intership-page-item-venom" width="142" height="71" viewBox="0 0 142 71" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M141.702 71H3.76044C-0.304711 56.2073 -3.81689 37.6794 8.70194 35.0555C19.6101 32.7692 25.9684 36.8389 29.8657 39.3334C31.94 40.661 33.3172 41.5425 34.3121 40.7819C34.9861 40.7258 35.9296 38.6262 34.3121 30.6766C34.2859 30.5478 34.2592 30.4169 34.2321 30.2838C32.2743 20.6732 28.0629 0 39.9565 0C40.6091 0 41.3101 0.062226 42.0626 0.191919C50.3618 1.62228 53.6382 7.2638 56.1116 11.5226C58.0067 14.7859 59.4305 17.2374 62.2811 16.3606C65.0769 15.5007 66.5916 12.2628 68.0841 9.07245C70.0995 4.76427 72.0743 0.542901 77.1081 2.38145C82.3168 4.28389 83.8334 11.7819 84.9857 17.4791C85.7717 21.365 86.3883 24.4131 87.8913 24.2765C89.2623 24.1519 90.472 21.4467 91.921 18.2062C94.3896 12.6856 97.5529 5.61151 103.392 7.09731C108.293 8.34437 110.131 18.0713 111.871 27.2835C113.422 35.4897 114.895 43.2874 118.388 44.3189C121.425 45.2159 123.67 44.4451 125.68 43.7552C128.576 42.7611 130.982 41.935 134.562 46.5084C137.338 50.0538 140.714 60.2297 141.702 71Z"
                              fill="#874088"/>
                    </svg>

                </div>
                <div className="intership-page-item">
                    <span className="intership-page-item__name">Уровень 6</span>
                    <button className="intership-page-item__button">Начать</button>
                    <div className="intership-page-item-stepper">
                        01/30
                    </div>

                    <svg className="intership-page-item-venom" width="142" height="71" viewBox="0 0 142 71" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M141.702 71H3.76044C-0.304711 56.2073 -3.81689 37.6794 8.70194 35.0555C19.6101 32.7692 25.9684 36.8389 29.8657 39.3334C31.94 40.661 33.3172 41.5425 34.3121 40.7819C34.9861 40.7258 35.9296 38.6262 34.3121 30.6766C34.2859 30.5478 34.2592 30.4169 34.2321 30.2838C32.2743 20.6732 28.0629 0 39.9565 0C40.6091 0 41.3101 0.062226 42.0626 0.191919C50.3618 1.62228 53.6382 7.2638 56.1116 11.5226C58.0067 14.7859 59.4305 17.2374 62.2811 16.3606C65.0769 15.5007 66.5916 12.2628 68.0841 9.07245C70.0995 4.76427 72.0743 0.542901 77.1081 2.38145C82.3168 4.28389 83.8334 11.7819 84.9857 17.4791C85.7717 21.365 86.3883 24.4131 87.8913 24.2765C89.2623 24.1519 90.472 21.4467 91.921 18.2062C94.3896 12.6856 97.5529 5.61151 103.392 7.09731C108.293 8.34437 110.131 18.0713 111.871 27.2835C113.422 35.4897 114.895 43.2874 118.388 44.3189C121.425 45.2159 123.67 44.4451 125.68 43.7552C128.576 42.7611 130.982 41.935 134.562 46.5084C137.338 50.0538 140.714 60.2297 141.702 71Z"
                              fill="#874088"/>
                    </svg>

                </div>
            </div>
        </main>
    );
};