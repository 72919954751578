import {Dispatch, FC, ReactNode, SetStateAction, MouseEvent} from 'react';
import {createPortal} from "react-dom";
import './Modal.scss';

export interface IModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>
    children: ReactNode;
}

export const Modal: FC<IModalProps> = ({children, isOpen, setIsOpen}) => {
    function onWrapperClick(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(false);
    }

    function onContentClick(e: MouseEvent) {
        e.stopPropagation();
    }

    if (!isOpen) return null;
    return createPortal(<div className={'modal-wrapper'} onClick={onWrapperClick}>
        <div onClick={onContentClick} className="modal-content">
            {children}
        </div>
    </div>, document.body)
}

