import {FC} from 'react';
import {Button} from "../../components/Button/Button";
import './SignInAdmin.scss';
import {Input} from "../../components/Input/Input";
import {HiddenEyeIcon} from "../../assets/icons/HiddenEyeIcon";

export interface ISignInAdminPageProps {

}

export const SignInAdminPage: FC<ISignInAdminPageProps> = () => {
    return (
        <main className={'sign-in-admin'}>
            <form className={'sign-in-admin-form'}>
                <h1 className={'sign-in-admin-form-title'}>
                    Вход в систему
                </h1>
                <Input wrapperClassName={'login-input'} label={'Логин'} placeholder={'Введите логин'}
                />
                <Input wrapperClassName={'password-input'} label={'Пароль'} placeholder={'Введите пароль'}
                       rightContent={<HiddenEyeIcon/>}
                       headerRightContent={
                           <a className={'sign-in-admin-forgot-password-button'}>Забыли пароль?</a>
                       }/>
                <Button className={'sign-in-admin-submit-button'}>Войти</Button>
            </form>
        </main>
    );
};
