// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  /*   margin-left: 110px; */
}

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 18px;
  padding: 0 8px;
  border-radius: 90px;
  border: 1px solid currentColor;
  color: rgb(232, 215, 232);
  font-size: 12px;
}
@media (max-width: 450px) {
  .breadcrumb {
    font-size: 10px;
  }
}
.breadcrumb:last-of-type:after {
  display: none;
}
.breadcrumb::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: currentColor;
  transform: translate(50%, -50%);
}
.breadcrumb.active {
  color: #731975;
  font-weight: bold;
  background-color: rgb(232, 215, 232);
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/Breadcrumbs.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,SAAA;EACA,WAAA;EACF,0BAAA;AACA;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA;EACA,eAAA;AACF;AAAE;EAXF;IAYI,eAAA;EAGF;AACF;AAKI;EACE,aAAA;AAHN;AAOE;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,wBAAA;EACA,+BAAA;AALJ;AAQE;EACE,cAAA;EACA,iBAAA;EACA,oCAAA;AANJ","sourcesContent":[".breadcrumbs {\n  display: flex;\n  justify-content: flex-start;\n  gap: 16px;\n  width: 100%;\n/*   margin-left: 110px; */\n}\n\n.breadcrumb {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  height: 18px;\n  padding: 0 8px;\n  border-radius: 90px;\n  border: 1px solid currentColor;\n  color: rgba(232, 215, 232, 1);\n  font-size: 12px;\n  @media (max-width: 450px){\n    font-size: 10px;\n  }\n\n  &:last-of-type {\n  //  background: rgba(232, 215, 232, 1);\n  //  color: rgba(115, 25, 117, 1);\n  //  border: none;\n  //  pointer-events: none;\n\n    &:after {\n      display: none;\n    }\n  }\n\n  &::after {\n    content: \"\";\n    position: absolute;\n    top: 50%;\n    right: -8px;\n    width: 4px;\n    height: 4px;\n    border-radius: 50%;\n    background: currentColor;\n    transform: translate(50%, -50%);\n  }\n\n  &.active {\n    color: #731975;\n    font-weight: bold;\n    background-color: rgba(232, 215, 232, 1);\n  }\n \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
