import './LinkButton.scss'
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';

export interface IButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children: ReactNode,
    className?: string
    onPageChange: (page: number) => void
    handlePositionChange: (page: number) => void
    // variant?: ButtonVariants
    direction: LinkButtonDirections
}

export type LinkButtonDirections = 'right' | 'left';
export const LinkButton: FC<IButtonProps> = ({ children, className = "", direction = 'left', onPageChange, ...rest }) => {
    const handleClick = () => {
        const page = direction === 'right' ? 1 : -1;
        onPageChange(page);
    };
    return (
        <button onClick={handleClick} {...rest} className={`link-button ${className} link-button-${direction}`}>
            <svg className='link-button-chevron' width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2501 20.5832L8.66675 12.9998L16.2501 5.4165" strokeWidth="2"
                    strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {children}
        </button>
    );
};