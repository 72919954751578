// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-rule {
  width: 180px;
  height: 53px;
  position: relative;
}
.floating-rule.purple {
  color: rgb(79, 71, 233);
}
.floating-rule.pink {
  color: rgb(230, 148, 210);
}
.floating-rule.lightpurple {
  color: rgb(167, 119, 234);
}
.floating-rule.orange {
  color: rgb(231, 249, 136);
}
.floating-rule-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
.floating-rule-image-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  width: 46px;
  height: 48px;
}
.floating-rule-text {
  position: absolute;
  top: 50%;
  left: 55px;
  transform: translateY(-50%);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/FloatingRules/FloatingRules.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EAEA,kBAAA;AAAF;AAEE;EACE,uBAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,yBAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ;AAME;EAUE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;EACA,YAAA;AAbJ;AADI;EACE,kBAAA;EACA,QAAA;EACA,OAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;AAGN;AAQE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AANJ","sourcesContent":[".floating-rule {\n  width: 180px;\n  height: 53px;\n\n  position: relative;\n\n  &.purple {\n    color: rgba(79, 71, 233, 1);\n  }\n\n  &.pink {\n    color: rgba(230, 148, 210, 1);\n  }\n\n  &.lightpurple {\n    color: rgba(167, 119, 234, 1);\n  }\n\n  &.orange {\n    color: rgba(231, 249, 136, 1);\n  }\n\n  &-image {\n    &-wrapper {\n      position: absolute;\n      top: 50%;\n      left: 0;\n      transform: translate(0%, -50%);\n      width: 46px;\n      height: 48px;\n    }\n\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 100%;\n    height: 100%;\n  }\n\n  &-text {\n    position: absolute;\n    top: 50%;\n    left: 55px;\n    transform: translateY(-50%);\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 24px;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
