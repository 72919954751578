// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  /* 	 padding: 0px;
  	margin: 0px;  */
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */
a, a:link, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */
aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  /* max-width: 100%; */
  height: auto;
}

address {
  font-style: normal;
}

/* Form  */
input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
}

input::-ms-clear {
  display: none;
}

button, input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/reset.css"],"names":[],"mappings":"AAAA;EACA;kBAAA;EAEC,YAAA;AACD;;AAEA;;;EAGC,sBAAA;AACD;;AAEA,UAAA;AAEA;EAEI,qBAAA;AADJ;;AAIA;EACI,qBAAA;AADJ;;AAIA,WAAA;AAEA;EACC,cAAA;AAFD;;AAKA;EACI,kBAAA;EACH,oBAAA;AAFD;;AAKA;EACC,gBAAA;AAFD;;AAKA;EACC,mBAAA;AAFD;;AAKA;EACC,qBAAA;EACA,YAAA;AAFD;;AAKA;EACE,kBAAA;AAFF;;AAKC,UAAA;AAEA;EACA,oBAAA;EACG,kBAAA;AAHJ;;AAOA;EACC,aAAA;AAJD;;AAOA;EACI,qBAAA;EACA,gBAAA;EACA,6BAAA;EACA,gBAAA;EACA,eAAA;AAJJ;;AAOA;;EAEI,aAAA;AAJJ;;AAOA;EACC,UAAA;EACA,SAAA;AAJD;;AAOA;EACC,eAAA;AAJD;;AAOA;EACC,cAAA;AAJD","sourcesContent":["* {\n/* \t padding: 0px;\n\tmargin: 0px;  */\n\tborder: none;\n}\n\n*,\n*::before,\n*::after {\n\tbox-sizing: border-box;\n}\n\n/* Links */\n\na, a:link, a:visited  {\n   \n    text-decoration: none;\n}\n\na:hover  {\n    text-decoration: none;\n}\n\n/* Common */\n\naside, nav, footer, header, section, main {\n\tdisplay: block;\n}\n\nh1, h2, h3, h4, h5, h6, p {\n    font-size: inherit;\n\tfont-weight: inherit;\n}\n\nul, ul li {\n\tlist-style: none;\n}\n\nimg {\n\tvertical-align: top;\n}\n\nimg, svg {\n\t/* max-width: 100%; */\n\theight: auto;\n}\n\naddress {\n  font-style: normal;\n}\n\n /* Form  */\n\n input, textarea, button, select {\n\tfont-family: inherit;\n    font-size: inherit;\n    \n}\n\ninput::-ms-clear {\n\tdisplay: none;\n}\n \nbutton, input[type=\"submit\"] {\n    display: inline-block;\n    box-shadow: none;\n    background-color: transparent;\n    background: none;\n    cursor: pointer;\n}\n\ninput:focus, input:active,\nbutton:focus, button:active {\n    outline: none;\n}\n\nbutton::-moz-focus-inner {\n\tpadding: 0;\n\tborder: 0;\n}\n\nlabel {\n\tcursor: pointer;\n}\n\nlegend {\n\tdisplay: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
