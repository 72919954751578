// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.links-header-head {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  color: white;
  margin-bottom: 40px;
}
.links-header-wards {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.links-header-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.links-header-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/LinksHeader/LinksHeader.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,oBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;EACA,mBAAA;AADJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAHJ","sourcesContent":["\n.links-header {\n  &-head {\n    display: flex;\n    align-items: stretch;\n    justify-content: center;\n    flex-direction: column;\n    gap: 16px;\n    color: white;\n    margin-bottom: 40px;\n  }\n  &-wards {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  &-actions {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  &-buttons {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
