import axios from "axios";
import { instance } from "./main";

export const getQuestionsAPI = () => {
  return instance.get("/interview/question");
};

export const sendVideoAPI = (file: Blob, vquestId: number) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("vquestId", vquestId.toString());

  return instance.post(
    "https://survey.skill-radar.ru/interview/video",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
