import { Navigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks"
import './Hello.scss'
import { useEffect } from "react"
import { getActualUserThunk } from "../../redux/surveyReducer"
import { useLogCheck } from "routes/hooks/mainHooks"

export const Hello = () => {
    const name = useAppSelector(state => state.surveyReducer.actualUser.first_name)
    useLogCheck()

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getActualUserThunk()).catch(err => console.log(err)).finally(() => {})
    }, [])
    {
        return name ? (
            <main className={"hello"}>
                <h1 className={"hello__title"}>Здравствуйте, {name || "John Doe"}</h1>
                <p className={'hello__text'}>
                    Поздравляем Вас с началом прохождения стажировки в компании «SkillRadar». Мы рады видеть Вас в своих рядах!  Предлагаем ознакомиться с историей и ценностями компании, для того чтобы Вам было легче стать её частью.
                </p>
                <p className={'hello__text'}>
                    Для успешного прохождения адаптации необходимо изучить и заполнить нижепредставленные блоки. Желаем удачи!
                </p>
                <a href={"/roadmap-start"} className={"hello__next-btn"}>Далее</a>
            </main>) : (<div>

            </div>)
    }
}
