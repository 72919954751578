import {FC, SVGProps} from 'react';

export const CameraIcon: FC<SVGProps<SVGSVGElement>> = ({...restProps}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.619 23.8239H7.24565C3.55898 23.8239 2.33398 21.3739 2.33398 18.9122V9.08891C2.33398 5.40225 3.55898 4.17725 7.24565 4.17725H14.619C18.3057 4.17725 19.5307 5.40225 19.5307 9.08891V18.9122C19.5307 22.5989 18.294 23.8239 14.619 23.8239Z"
                stroke="currentColor" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M22.7726 19.9502L19.5293 17.6752V10.3135L22.7726 8.03849C24.3593 6.93015 25.666 7.60682 25.666 9.55515V18.4452C25.666 20.3935 24.3593 21.0702 22.7726 19.9502Z"
                stroke="currentColor" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M13.416 12.8335C14.3825 12.8335 15.166 12.05 15.166 11.0835C15.166 10.117 14.3825 9.3335 13.416 9.3335C12.4495 9.3335 11.666 10.117 11.666 11.0835C11.666 12.05 12.4495 12.8335 13.416 12.8335Z"
                stroke="currentColor" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};
