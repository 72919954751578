// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round-button, .round-button-red, .round-button-blue {
  width: 60px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
}
.round-button-blue {
  background: rgb(26, 113, 255);
}
.round-button-red {
  background: rgb(255, 73, 73);
}`, "",{"version":3,"sources":["webpack://./src/components/RoundButton/RoundButton.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EAEE,6BAAA;AAAJ;AAGE;EAEE,4BAAA;AAFJ","sourcesContent":[".round-button {\n  width: 60px;\n  aspect-ratio: 1/1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  border-radius: 50%;\n\n  &-blue {\n    @extend .round-button;\n    background: rgba(26, 113, 255, 1);\n  }\n\n  &-red {\n    @extend .round-button;\n    background: rgba(255, 73, 73, 1)\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
