import {FC, SVGProps} from 'react';

export const PlusIcon: FC<SVGProps<SVGSVGElement>> = ({...restProps}) => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path d="M11.6021 5.78809V11.2881M11.6021 11.2881V16.7881M11.6021 11.2881H17.1021M11.6021 11.2881H6.10205"
                  stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};




