import { useNavigate } from "react-router-dom";
import './Landing.scss';
import { LandingMain } from "./LandingMain/LandingMain";
import { useEffect } from "react";
import AOS from "aos";

const Landing = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);
    const navigate = useNavigate();

    return (
        <div className='landing'>
            <header className="landing-header">
                <h1 className="landing__title" data-aos="zoom-in-right">
                    Откройте для себя незаменимый инструмент управления человеческими ресурсами!
                </h1>
                <small className="landing__after-title" data-aos="zoom-in-right">
                    SkillRadar представляет собой многофункциональное комплексное сочетание всех HR– инструментов в одном современном приложении
                </small>
                <button onClick={() => navigate("/sign-in")} className="landing__enter-button" data-aos="zoom-in-right">
                    Войти в систему
                </button>
            </header>
            {/* <LandingMain /> */}
        </div>


    );
};

export default Landing;