import "./AdminPanel.scss";
import { animate, stagger } from "framer-motion"
import { FC, useEffect, useState } from "react";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AdminSurveyType } from "redux/adminReducer";
import { getResult } from "API/panelAPI";

type ResultType = {
    anxious: number
    emotional: number
    epileptoid: number
    hyperthymic: number
    hysteroid: number
    paranoid: number
    schizoid: number
}


export const data = {
    labels: ['Директором', 'Заместителем', 'Руководителем', 'Работником', 'Менеджером'],
    datasets: [
        {
            data: [40, 25, 12, 13, 10],
            backgroundColor: [
                'rgba(250, 202, 21, 1)',
                'rgba(49, 196, 141, 1)',
                'rgba(231, 70, 148, 1)',
                'rgba(144, 97, 249, 1)',
                'rgba(104, 117, 245, 1)',
            ],
            percentageInnerCutout: 40,
            borderWidth: 0,
            spacing: 2,
        },
    ],

};
ChartJS.register(ArcElement, Tooltip, Legend);

export const AdminPanel = ({ survey }: { survey: AdminSurveyType }) => {
    const [results, setResults] = useState<ResultType>({} as ResultType)
     useEffect(() => {
        animate(".progress", { opacity: 1 }, { delay: stagger(0.1) })
        animate(".admin-panel-doughnut-legend-item", { opacity: 1 }, { delay: stagger(0.2) })
    }, []);
    useEffect(() => {
        getResult(survey.Id).then(res => setResults(res.data))
    }, []) 
    /* useEffect(() => {
        const progressElements = document.querySelectorAll(".progress");
        const legendItems = document.querySelectorAll(".admin-panel-doughnut-legend-item");

        if (progressElements.length > 0) {
            animate(progressElements, { opacity: 1 }, { delay: stagger(0.1) });
        }

        if (legendItems.length > 0) {
            animate(legendItems, { opacity: 1 }, { delay: stagger(0.2) });
        }
    }, []);

    useEffect(() => {
        getResult(survey.Id).then(res => setResults(res.data))
    }, [survey.Id]); */
    return (
        <main className={'admin-panel'}>
            <div className="admin-panel-body">
                <div className="admin-panel-progresses">
                    <Progress progressValue={Math.round(results.hysteroid)} firstTitle='Истероидный' secondTitle='' />
                    <Progress progressValue={Math.round(results.epileptoid)} firstTitle='Эпилептоидный' secondTitle='' />
                    <Progress progressValue={Math.round(results.paranoid)} firstTitle='Параноидальный' secondTitle='' />
                    <Progress progressValue={Math.round(results.emotional)} firstTitle='Эмотивный' secondTitle='' />
                    <Progress progressValue={Math.round(results.schizoid)} firstTitle='Шизоидный' secondTitle='' />
                    <Progress progressValue={Math.round(results.hyperthymic)} firstTitle='Гипертимный' secondTitle='' />
                    <Progress progressValue={Math.round(results.anxious)} firstTitle='Тревожный' secondTitle='' />
                </div>
                  <div className="admin-panel-doughnut">
                    <span className="admin-panel-doughnut-description lato-12-400">ОПИСАНИЕ</span>
                    <span className="admin-panel-doughnut-title lato-24-700">Подробные результаты</span>
                    <span className="admin-panel-doughnut-small lato-12-400">подробное описание человека исходя из результатов тестирования</span>
                    <div className="admin-panel-doughnut-graphic-wrapper">
                        <Doughnut className={'admin-panel-doughnut-graphic'} options={{
                            cutout: 55,
                            plugins: {
                                legend: {
                                    display: false
                                },
                            },
                        }} data={data} />
                    </div>
                    <div className="admin-panel-doughnut-legend">
                        {data.datasets[0].data.map(((el, index) => {
                            return <div className="admin-panel-doughnut-legend-item lato-20-400">
                                <div style={{ background: data.datasets[0].backgroundColor[index] }} className="admin-panel-doughnut-legend-item-color" />
                                <span className="admin-panel-doughnut-legend-item-text">
                                    на {el}% готов стать {data.labels[index].toLowerCase()}
                                </span>
                            </div>
                        }))}

                    </div>
                </div> 
            </div>

        </main>
    );
};

export interface ProgressProps {
    progressValue: number,
    firstTitle: string,
    secondTitle: string,
    activePosition?: ProgressActivePositions
}

export type ProgressActivePositions = 'left' | 'right';

export const Progress: FC<ProgressProps> = ({
    progressValue,
    firstTitle,
    secondTitle,
    activePosition = 'left'
}) => {
    return <div className="progress">
        <div className={`progress-bar progress-bar--${activePosition}`}>
            <div className="progress-bar-active" style={{ width: `${progressValue}%` }} />
        </div>
        <div className="progress-info">
            <div className="progress-info-col">
                <span className="progress-percentage lato-24-700">{progressValue}%</span>
                <span className="progress-name">{firstTitle}</span>
            </div>
            <div className="progress-info-col">
                {/*  <span className="progress-percentage lato-24-700">{100 - progressValue}%</span>
                <span className="progress-name">{secondTitle}</span> */}
            </div>
        </div>
    </div>
}