import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTestQuestionsAPI } from "../API/testAPI";

type PsychoType = {
  id: number;
  answers: Array<AnswerType>;
  question: string;
  answer: AnswerType;
};

export type AnswerType =
{
  id: number,
  answer: number
}

let initialState = {
  fullQuestions: [
  
  ] as Array<PsychoType>,
};

export type initialStateType = typeof initialState;

export const getPsychoQuestions = createAsyncThunk(
  'psycho/getPsychoQuestions',
  async (_, {dispatch}) => 
  {
    getTestQuestionsAPI().then(res => {
      if (res.status == 200) 
      {
        dispatch(setQuestion(res.data));
      }
    })
  }
)

const psychoSlice = createSlice({
  name: "psycho",
  initialState,
  reducers: {
    setUserAnswer(state, action: PayloadAction<{questionIndex: number, answer: AnswerType}>){
      state.fullQuestions[action.payload.questionIndex].answer = action.payload.answer;
    },    
    setQuestion(state, action: PayloadAction<PsychoType[]>) 
    {
      state.fullQuestions = action.payload
    }, 
    saveQuestion(state, action: PayloadAction<PsychoType>) {
      state.fullQuestions.map((q) => {
        if (q.id === action.payload.id) {
          return { ...q, answer: action.payload.answer };
        }
      });
    },
  },
});

export const { saveQuestion, setQuestion, setUserAnswer } = psychoSlice.actions;
export default psychoSlice.reducer;
