import React, {SyntheticEvent, useEffect, useState} from 'react';
import './SignIn.scss';
import {sendCodeAPI, sendNumberAPI} from '../../API/loginAPI';
import {useNavigate} from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import useRedirect, {useNoAuthCheck} from '../hooks/mainHooks';
import {setNumber} from '../../redux/authReducer';
import {useAppDispatch} from '../../redux/reduxHooks';
import {FloatingRule} from "../../components/FloatingRules/FloatingRules";

const SignIn = () => {
    useNoAuthCheck()
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
    const dispatch = useAppDispatch()
    const [code, setCode] = useState<string | null>(null)
    const [messageSent, setMessageSent] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const navigate = useNavigate()

    const handleChangerNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!messageSent)
            setPhoneNumber(event.target.value)
    }

    const handleChangerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!codeSent)
            setCode(event.target.value)
    }

    const sendNumber = (e: SyntheticEvent) => {
        e.preventDefault();
        if (phoneNumber) {
            dispatch(setNumber(phoneNumber))
            sendNumberAPI(phoneNumber).then((res) => {
                if (res.status === 201) setMessageSent(true)
            }).catch(err => {
                if (err.response.status === 500) {
                    navigate('/login')
                }
            })

        }
    }

    const sendCode = (e: SyntheticEvent) => {
        e.preventDefault();
        if (code && phoneNumber) {
            sendCodeAPI(phoneNumber, code).then(res => {
                if (res.status === 200) {
                    localStorage.setItem('token', res.data.jwt)
                    setCodeSent(true)
                    navigate('/entersuccess')
                }
            }).catch(err => console.error(err))

        }
    }

    return (
        <main className={'sign-in'}>
            <FloatingRule className={'floating-rule-1'} color={'purple'} text={"Юрист"}/>
            <FloatingRule className={'floating-rule-2'} color={'pink'} text={"Менеджер"}/>
            <FloatingRule className={'floating-rule-3'} color={'lightpurple'} text={"Бухгалтер"}/>
            <FloatingRule className={'floating-rule-4'} color={'orange'} text={"Программист"}/>
            {!messageSent ? (
                    <form onSubmit={sendNumber} className="sign-in-form">
                        <h1 className="sign-in-form__title">Вход в систему</h1>
                        <small>Найдите подходящую для Вас вакансию вместе со SkillRadar</small>
                        <div className="sign-in-form-input-block">
                            <ReactInputMask onChange={handleChangerNumber} mask={'+79999999999'}
                                            className="sign-in-form-input-block__input" placeholder='+7 (___) ___ __-__'
                                            type="phone"/>
                            <button type="button" onClick={sendNumber} className="sign-in-form-input-block__button">Войти
                            </button>
                        </div>
                        <small>SkillRadar вышлет вам SMS с логином и паролем</small>
                    </form>)
                : (<form onSubmit={sendCode} className="sign-in-form--alt">
                    <h1 className="sign-in-form--alt__title">Вход в систему</h1>
                    <small>Мы отправили вам пароль в SMS <br/> на номер +7 (___) ___-__-__</small>
                    <div className="sign-in-form--alt-code-input-block-wrapper">
                        <span className="sign-in-form--alt-code-input-block__label">
                            Введите пароль из SMS
                        </span>
                        <div className="sign-in-form--alt-code-input-block">
                            <ReactInputMask onChange={handleChangerCode} mask={'999999'}
                                            className="sign-in-form--alt-code-input-block__input" placeholder='Пароль'
                                            type="text"/>
                            <button className="sign-in-form--alt-code-input-block__button" onClick={sendCode}
                                    type="button">Войти
                            </button>
                        </div>
                    </div>
                    <a className='sign-in-form--alt__change' href="/sign-in">
                        Заменить номер?
                    </a>
                </form>)
            }
        </main>
    );
};

export default SignIn;