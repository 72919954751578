// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
}
.default-layout__body {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  height: 100%;
}
.default-layout__sidebar {
  flex-shrink: 0;
  height: 100%;
}
.default-layout__page-content {
  flex-grow: 1;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layouts/DefaultLayout/DefaultLayout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,oBAAA;EACA,iBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,SAAA;EACA,YAAA;AAEJ;AAAE;EACE,cAAA;EACA,YAAA;AAEJ;AAAE;EACE,YAAA;EACA,YAAA;AAEJ","sourcesContent":[".default-layout{\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: stretch;\n  min-height: 100vh;\n  &__body{\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-start;\n    gap: 15px;\n    height: 100%;\n  }\n  &__sidebar{\n    flex-shrink: 0;\n    height:100%;\n  }\n  &__page-content{\n    flex-grow: 1;\n    height:100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
