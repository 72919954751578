// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes transform-bounce {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes transform-max-width {
  0% {
    width: 0;
  }
}
.sign-in-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
}
.sign-in-admin-form {
  background: rgb(232, 215, 232);
  padding: 32px;
  gap: 24px;
  border-radius: 24px;
  width: 480px;
}
.sign-in-admin-form-title {
  font-size: 46px;
  color: rgb(115, 25, 117);
  margin: 0 0 24px;
}
.sign-in-admin-forgot-password-button {
  color: rgb(115, 25, 117);
}
.sign-in-admin-submit-button {
  width: 100%;
  height: 41px;
}

.login-input {
  margin-bottom: 16px;
}

.password-input {
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.scss","webpack://./src/routes/SignInAdmin/SignInAdmin.scss"],"names":[],"mappings":"AASA;EACE;IACE,mBAAA;ECRF;EDUA;IACE,sBAAA;ECRF;EDUA;IACE,mBAAA;ECRF;EDUA;IACE,sBAAA;ECRF;EDUA;IACE,mBAAA;ECRF;AACF;ADYA;EACE;IACE,QAAA;ECVF;AACF;AAnBA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAqBF;AAnBE;EACE,8BAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;AAqBJ;AApBI;EACE,eAAA;EACA,wBDdG;ECeH,gBAAA;AAsBN;AAnBE;EACE,wBAAA;AAqBJ;AAnBE;EACE,WAAA;EACA,YAAA;AAqBJ;;AAjBA;EACE,mBAAA;AAoBF;;AAjBA;EACE,mBAAA;AAoBF","sourcesContent":["$darkblue-1: #2C2F48;\n\n$purple: rgba(115, 25, 117, 1);\n$red: rgba(224, 36, 36, 1);\n$grey: rgba(107, 114, 128, 1);\n$light-grey: rgba(181, 183, 192, 1);\n$light-purple: rgba(242, 239, 255, 1);\n$pre-black: rgba(49, 49, 49, 1);\n\n@keyframes transform-bounce {\n  0%{\n    transform: scale(0);\n  }\n  25%{\n    transform: scale(1.25);\n  }\n  50%{\n    transform: scale(1);\n  }\n  75%{\n    transform: scale(1.25);\n  }\n  100%{\n    transform: scale(1);\n  }\n}\n\n\n@keyframes transform-max-width {\n  0%{\n    width: 0;\n  }\n}\n","@import 'src/assets/styles/variables.scss';\n\n.sign-in-admin {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20vh;\n\n  &-form {\n    background: rgba(232, 215, 232, 1);\n    padding: 32px;\n    gap: 24px;\n    border-radius: 24px;\n    width: 480px;\n    &-title {\n      font-size: 46px;\n      color: $purple;\n      margin: 0 0 24px;\n    }\n  }\n  &-forgot-password-button{\n    color: rgba(115, 25, 117, 1);\n  }\n  &-submit-button{\n    width:100%;\n    height: 41px;\n  }\n}\n\n.login-input{\n  margin-bottom: 16px;\n}\n\n.password-input{\n  margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
