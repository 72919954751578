import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DefaultLayout from "./layouts/DefaultLayout/DefaultLayout";
import Landing from "./routes/Landing/Landing";
import SignIn from "./routes/SignIn/SignIn";
import Login from "./routes/Login/Login";
import Questionary from "./routes/Questionary/Questionary";
import Video from "./routes/Video/Video";
import { setAuth } from './redux/authReducer';
import { useAppDispatch } from './redux/reduxHooks';
import { Test } from './routes/Test/Test';
import { AllSuccess } from './routes/TextPages/AllSuccess/AllSuccess';
import { EnterSuccess } from './routes/TextPages/EnterSuccess/EnterSuccess';
import { QuestionarySuccess } from './routes/TextPages/QuestionarySuccess/QuestionarySuccess';
import { VideoSuccess } from "./routes/TextPages/VideoSuccess/VideoSuccess";
import Registration from "./routes/Registration/Registration";
import RoadmapLayout from "./layouts/RoadmapLayout/RoadmapLayout";
import { MentorsPage } from "./routes/Mentors/MentorsPage";
import { VideoAcqPage } from "./routes/VideoACQ/VideoACQPage";
import { MentorPage } from "./routes/Mentor/MentorPage";
import { ChatsPage } from "./routes/Chats/ChatsPage";
import { ChatPage } from "./routes/Chat/ChatPage";
import { RoadmapStartPage } from "./routes/RoadmapStart/RoadmapStartPage";
import { Training } from "./routes/Training/Training";
import { Intership } from "./routes/Intership/Intership";
import { Plan } from "./routes/Plan/Plan";
import { Workers } from "./routes/Workers/Workers";
import { Hello } from './routes/Hello/Hello';
import { ListPanel } from 'routes/MainPanel/ListPanel/ListPanel';
import { AnketaData } from "./routes/MainPanel/AnketaData/AnketaData";
import { AdminPanel } from "./routes/MainPanel/AdminPanel/AdminPanel";
import { AnketaVideo } from 'routes/MainPanel/AnketaVideo/AnketaVideo';
import { PersonPanel } from 'routes/MainPanel/PersonPanel/PersonPanel';
import { Directions } from "./routes/Directions/Directions";
import { MetaTest } from "./routes/MetaTest/MetaTest";
import { MetaTestAnswer } from "./routes/MetaTestAnswer/MetaTestAnswer";

import { Interview } from "./routes/Interview/Interview";
import "aos/dist/aos.css";
import { SettingsPanel } from 'routes/MainPanel/SettingsPanel/SettingsPanel';
import { AdminLayout } from 'layouts/AdminLayout/AdminLayout';
import { PrivateOffice } from 'routes/MainPanel/PrivateOffice/PrivateOffice';
import { SignInAdminPage } from "./routes/SignInAdmin/SignInAdmin";
import { HRDepartmentPage } from "./routes/HRDepartmentPage/HRDepartmentPage";
import { AddEmployeePage } from "./routes/AddEmployeePage/AddEmployeePage";
import { JobTitleTable } from "./components/JobTitleTable/JobTitleTable";
import { JobTitlesPage } from "./routes/JobTitlesPage/JobTitlesPage";
import { HRList } from 'routes/MainPanel/HRList/HRList';


function App() {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            dispatch(setAuth(true));
        }
    }, [localStorage]);
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<DefaultLayout />}>
                    <Route path={'/'} element={<Landing />} />
                    <Route path={'/sign-in'} element={<SignIn />} />
                    <Route path={'/questionary'} element={<Questionary />} />
                    <Route path={'/video'} element={<Video />} />
                    <Route path={'/interview'} element={<Interview />} />
                    <Route path={'/test'} element={<Test />} />
                    <Route path={'/allsuccess'} element={<AllSuccess />} />
                    <Route path={'/entersuccess'} element={<EnterSuccess />} />
                    <Route path={'/videosuccess'} element={<VideoSuccess />} />
                    <Route path={'/questionarysuccess'} element={<QuestionarySuccess />} />
                    <Route path={'/login'} element={<Login />} />
                    <Route path={'/registration'} element={<Registration />} />
                </Route>
                <Route element={<RoadmapLayout />}>
                    <Route path={'/hello'} element={<Hello />} />
                    <Route path={'/chats'} element={<ChatsPage />} />
                    <Route path={'/video-acq'} element={<VideoAcqPage />} />
                    <Route path={'/mentors'} element={<MentorsPage />} />
                    <Route path={'/mentor'} element={<MentorPage />} />
                    <Route path={'/intership'} element={<Training />} />
                    <Route path={'/training'} element={<Intership />} />
                    <Route path={'/plan'} element={<Plan sidebar={true} />} />
                    <Route path={'/roadmap-start'} element={<RoadmapStartPage />} />
                    <Route path={'/meta-test'} element={<MetaTest />}></Route>
                    <Route path={'/meta-test-answer'} element={<MetaTestAnswer />}></Route>
                </Route>
                <Route element={<RoadmapLayout withHeader={false} />}>
                    <Route path={'/chat'} element={<ChatPage />} />
                </Route>

                <Route path={'admin/sign-in'} element={<SignInAdminPage />} />
                <Route element={<AdminLayout />}>a
                    <Route path={'/hr-department'} element={<HRDepartmentPage />} />
                    <Route path={'/add-employee'} element={<AddEmployeePage />} />
                    <Route path={'/job-titles'} element={<JobTitlesPage />} />
                    {/* Новая страница админ панели ниже */}
                    {/* <Route path={'/settings-panel'} element={<SettingsPanel />} /> */}
                    <Route path={'admin/private-office'} element={<PrivateOffice />} />
                    <Route path={'admin/list-panel'} element={<ListPanel />} />
                    <Route path={'admin/person-panel/:id'} element={<PersonPanel />} />
                    <Route path={'admin/directions'} element={<Directions />} />
                    <Route path={'admin/hrlist'} element={<HRList />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;


