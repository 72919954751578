import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getActualUser } from "API/surveyAPI";

export type ActualUser = {
  first_name: string;
  last_name: string;
  middle_name: string;
  role_id: number;
};

export type SurveyType = {
  LastName: string;
  FirstName: string;
  MiddleName: string;
  BirthDate: string;
  City: string;
  Education: string;
  Email: string;
  YearWorkExperience: number;
  ReasonDismissal: string;
};

let initialState = {
  survey: {
    LastName: "",
    FirstName: "",
    MiddleName: "",
    BirthDate: "",
    City: "",
    Education: "",
    Email: "",
    YearWorkExperience: 1,
    ReasonDismissal: "",
  },
  actualUser: {} as ActualUser,
};

export type initialStateType = typeof initialState;

export const getActualUserThunk = createAsyncThunk(
  "survey/getActualUser",
  async (_, { dispatch }) => {
    getActualUser().then((res) => {
      dispatch(setActualUser(res.data));
    });
  }
);

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    saveSurvey(state, action: PayloadAction<SurveyType>) {
      const privateData = action.payload;
      state.survey = privateData;
    },
    setActualUser(state, action: PayloadAction<ActualUser>) {
      state.actualUser = action.payload;
    },
  },
});

export const { saveSurvey, setActualUser } = surveySlice.actions;
export default surveySlice.reducer;
