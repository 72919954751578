import { AdminSurveyType } from 'redux/adminReducer';
import './AnketaData.scss'
import { FC, useEffect, useState } from 'react';


export interface IAnketaDataProps {
    survey: AdminSurveyType
}

export const AnketaData: FC<IAnketaDataProps> = ({ survey }) => {

    const [file, setFile] = useState('')

   /*  useEffect(() => {
        if (survey.resume_path) getVideo(survey.resume_path).then(res => setFile(res.data))
    }, [survey]) */

    function downloadFile(filePath: string) {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = '';
        link.click();
    }
    const formatDate = (date: string) => {
        const first = date.slice(0, 10).split('-')
        return first[2] + '.' + first[1] + '.' + first[0]
    }

    const formatExperience = (years1: string): string => {
        let years = parseInt(years1)
        if (years === 1) {
            return `${years} год`;
        } else if (years > 1 && years < 5) {
            return `${years} года`;
        } else if (years % 10 === 1 && (years < 11 || years > 14)) {
            return `${years} год`;
        } else {
            return `${years} лет`;
        }
    };

    return (
        <div className='anketa-data'>
            {/* <LinksHeader /> */}

            <div className="anketa-data-body">
                <div className="anketa-data-left">
                    <div className="anketa-data-profile anketa-data-block">
                        <ProfileItem
                            lastName={survey.last_name}
                            name={survey.first_name}
                            fatherName={survey.middle_name}
                            position='Подтверждённая учётная запись'
                        />
                        <span className="anketa-data-phone lato-20-500">{survey.phonenumber}</span>
                        <a href='mailto:e-mail@gmail.com' className="anketa-data-email lato-20-500">{survey.email}</a>
                    </div>
                    <div className="anketa-data-experience anketa-data-block">
                        <ExperienceItem name='Образование' value={survey.education} />
                        <ExperienceItem name='Опыт работы' value={formatExperience(survey.year_work_experience)} />
                        <ExperienceItem name='Причина ухода с предыдущей работы'
                            value={survey.reason_dismissal} />
                    </div>
                </div>


                <div className="anketa-data-right">
                    <div className="anketa-data-parameters anketa-data-block">
                        <span className='lato-20-400 anketa-data-parameters-title'>
                            Дата рождения
                        </span>
                        <span className='lato-20-400 anketa-data-parameters-title'>
                            Место проживания
                        </span>
                        <span className='lato-20-400'>
                            {
                                survey.date_of_birth && formatDate(survey.date_of_birth)}
                        </span>
                        <span className='lato-20-400'>
                            {survey.city}
                        </span>
                    </div>

                    <div className="anketa-data-resume anketa-data-block">
                        <span className="anketa-data-resume-title lato-24-700">
                            Резюме кандидата
                        </span>
                        <button className='anketa-data-resume-button' onClick={(e) => { window.open(`https://s3.timeweb.com/f457174f-5999b11e-29c5-4b55-a452-33232e2640bb/${survey.resume_path}`, '_blank') }}>
                            Скачать
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export interface ProfileItemProps {
    name: string,
    lastName: string,
    fatherName: string,
    position: string,
}

const ProfileItem: FC<ProfileItemProps> = ({ name, lastName, fatherName, position }) => {
    {
        if (name && lastName && fatherName && position) {
            return <div className="profile">
                <div className="profile-image lato-20-700">{lastName.charAt(0)}{name.charAt(0)}</div>
                <div className="profile-body">
                    <span className="profile-name lato-20-700">{lastName} {name} {fatherName}</span>
                    <span className="profile-position lato-20-400">{position}</span>
                </div>
            </div>
        } else return <div>

        </div>
    }
}


export interface ExperienceItemProps {
    name: string;
    value: string | false;
}

const ExperienceItem: FC<ExperienceItemProps> = ({ name, value }) => {
    return <div className={'experience-item'}>
        <span className="experience-item-name lato-20-400">{name}</span>
        <span className="experience-item-value lato-20-400">{value}</span>
    </div>
}