import React, { useEffect, useState } from 'react';
import './RoadmapLayout.scss';
import { Outlet } from "react-router-dom";
import { RoadmapSidebar } from "../../components/RoadmapSidebar/RoadmapSidebar";
import Header from "../../components/Header/Header";
import { useAppSelector, useAppDispatch } from '../../redux/reduxHooks';


interface RoadmapLayoutProps {
    withHeader?: boolean
}

const RoadmapLayout = ({ withHeader = true }: RoadmapLayoutProps) => {

    const mentorSelected = useAppSelector(state => state.roadmapReducer.mentorSelected)
    const [onlyLogo, setOnlyLogo] = useState<boolean>(true)
    useEffect(() => {
        if (mentorSelected) {
            setOnlyLogo(false)
        }
        else setOnlyLogo(true)
    }, [mentorSelected])
    return (
        <div className="roadmap-layout">
            {mentorSelected && <RoadmapSidebar className="roadmap-layout__sidebar" />}
            <main className="roadmap-layout__page-content">
                {withHeader && <Header isShort={true} onlyLogo={onlyLogo} />}
                <Outlet />
            </main>
        </div>
    );
};

export default RoadmapLayout;

