import {DetailedHTMLProps, FC, InputHTMLAttributes, LabelHTMLAttributes, ReactNode} from 'react';
import './Input.scss';

export interface IInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string,
    wrapperClassName?: string;
    headerRightContent?: ReactNode;
    rightContent?: ReactNode;
    inputWrapperClassName?: string;
    labelClassName?: string;
    headerClassName?: string;
}

export const Input: FC<IInputProps> = ({
                                           label,
                                           wrapperClassName,
                                           inputWrapperClassName = '',
                                           headerClassName = '',
                                           labelClassName = '',
                                           rightContent,
                                           headerRightContent,
                                           ...restProps
                                       }) => {
    return (
        <label className={`input-block ${wrapperClassName}`}>
            {label && <header className={`input-block-header ${headerClassName}`}>
                <small className={`input-small ${labelClassName}`}>{label}</small>
                {headerRightContent && <div className="input-block-header-right-content">
                    {headerRightContent}
                </div>}
            </header>
            }
            <div className={`input-block-content ${inputWrapperClassName}`}>
                <input className='input-block-input' type="text" {...restProps}/>
                {rightContent && <div className="input-block-right-content">
                    {rightContent}
                </div>}
            </div>
        </label>
    );
};
