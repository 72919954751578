// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roadmap-start-page__next-btn {
  padding: 0 20px;
  height: 40px;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  outline: none;
  margin: 20px auto 0;
}`, "",{"version":3,"sources":["webpack://./src/routes/RoadmapStart/RoadmapStartPage.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,0CAAA;EACA,aAAA;EACA,mBAAA;AAFJ","sourcesContent":[".roadmap-start-page {\n  &__next-btn {\n    padding: 0 20px;\n    height: 40px;\n    color: white;\n    border-radius: 10px;\n   \n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    border: 1px solid rgba(255, 255, 255, 0.5); \n    outline: none;\n    margin: 20px auto 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
