import './Button.scss'
import {ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode} from 'react';

export interface IButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children: ReactNode,
    className?: string,
    variant?: ButtonVariants,
    size?: ButtonSize,
}

export type ButtonVariants = 'red' | 'purple' | 'grey' | 'outline' | 'flat';
export type ButtonSize = 'large' | 'medium' | 'small';
export const Button: FC<IButtonProps> = ({children, className = "", size ='large', variant = 'purple', ...rest}) => {
    return (
        <button {...rest} className={`button ${className} button-${variant} button-size-${size}`}>
            {children}
        </button>
    );
};