import axios from "axios";
import { formData } from "../routes/Questionary/Questionary";
import { instance } from "./main";

export const sendSurveyAPI = (values: formData, file: File) => {
  const formData = new FormData();
  const data = {} as any;
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      data[key] = values[key];
    }
  }
  formData.append("data", JSON.stringify(data));
  formData.append("file", file);
  return instance.post(
    "https://survey.skill-radar.ru/candidate/create",
    formData
    /*    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    } */
  );
};

export const getActualUser = () => {
  return instance.get("/user/info");
};
