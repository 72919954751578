import useRedirect from '../../hooks/mainHooks';
import '../TextPage.scss';
import './EnterSuccess.scss'

export const EnterSuccess = () => {
    useRedirect(!localStorage.getItem("token"), "/sign-in");
    return (
        <main className={'text-page'}>
            <div className="text-page-content">
                <h1 className="text-page__title">
                    Вход в систему пройден успешно
                </h1>
                <span className="text-page__text">
                    Приступайте к заполнению анкеты
                </span>

                <a href='/questionary'>
                    <button className='text-page__button'>
                        Перейти к анкете
                    </button>
                </a>
            </div>
        </main>
    );
};