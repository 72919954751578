import { RefObject, useEffect, useRef, useState } from 'react';
import './Header.scss';
import logonew from './logonew.jpg'
import HeaderBellIcon from "../Icons/HeaderBellIcon";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { animate, AnimatePresence, motion, stagger } from "framer-motion";

interface HeaderPreps {
    isShort?: boolean
    onlyLogo?: boolean
}

const Header = ({ isShort = false, onlyLogo = true }: HeaderPreps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const popupRef = useRef(null);
    const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void) => {
        const handleClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            if (ref.current && !ref.current.contains(target)) {
                callback();
            }
        };
        useEffect(() => {
            document.addEventListener('click', handleClick);
            return () => {
                document.removeEventListener('click', handleClick);
            };
        });
    };

    useClickOutside(popupRef, () => setIsOpen(false))
    return (
        <header className={'header'}>
            {/* добавил onlyLogo, без него логотип не отображался, в тернарный оператор добавил условие с ним, а то логотип отображался справа */}
            <div className={`header__body ${isShort && !onlyLogo ? 'short' : ''}`}>
                {onlyLogo && <a href="#" className={'header__logotype'}>
                    <img src={logonew} alt="SkillRadar" />
                    {!isShort && <div className="header-breadcrumbs">
                        <Breadcrumbs />
                    </div>}
                </a>}

                <div className="header__info">
                    <div className="header-popup-wrapper">
                        {/*    <button onClick={(e) => {
                            e.stopPropagation();
                            setIsOpen(!isOpen);
                        }} className="header__bell">
                            <HeaderBellIcon className="header__bell-icon"/>
                        </button> */}
                        <AnimatePresence>
                            {isOpen && (<motion.div initial={{ opacity: 0 }}
                                animate={{ opacity: 1, translateY: 20 }}
                                exit={{ opacity: 0, translateY: -20 }} className="header-popup" ref={popupRef}>
                                <div className="header-popup-head">
                                    <p className="header-popup__title">
                                        Уведомления
                                    </p>
                                    <button className="header-popup__close-button">
                                        Закрыть
                                    </button>
                                </div>
                                <div className="header-popup-content">
                                    <div className="header-popup-item">
                                        <svg className="header-popup-item__icon" width="24" height="26"
                                            viewBox="0 0 24 26"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.1149 24.5H4.66076C2.78487 24.5 1.26416 22.8211 1.26416 20.75V7M6.92515 19.5H19.3793C21.2552 19.5 22.7759 17.8211 22.7759 15.75V4.5C22.7759 2.42893 21.2552 0.75 19.3793 0.75H9.18955C7.31366 0.75 5.79296 2.42893 5.79296 4.5V18.25C5.79296 18.9404 6.29986 19.5 6.92515 19.5Z"
                                                stroke="#731975" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                        </svg>
                                        <div className="header-popup-item-body">
                                            <p className="header-popup-item__title">Анкета</p>
                                            <span className="header-popup-item__text">анкета кандидата при приеме на работу успешно заполнена и отправлена.</span>
                                        </div>
                                    </div>
                                    <div className="header-popup-item">
                                        <svg className="header-popup-item__icon" width="24" height="26"
                                            viewBox="0 0 24 26"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.1149 24.5H4.66076C2.78487 24.5 1.26416 22.8211 1.26416 20.75V7M6.92515 19.5H19.3793C21.2552 19.5 22.7759 17.8211 22.7759 15.75V4.5C22.7759 2.42893 21.2552 0.75 19.3793 0.75H9.18955C7.31366 0.75 5.79296 2.42893 5.79296 4.5V18.25C5.79296 18.9404 6.29986 19.5 6.92515 19.5Z"
                                                stroke="#731975" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                        </svg>
                                        <div className="header-popup-item-body">
                                            <p className="header-popup-item__title">Анкета</p>
                                            <span className="header-popup-item__text">анкета кандидата при приеме на работу успешно заполнена и отправлена.</span>
                                        </div>
                                    </div>
                                    <div className="header-popup-item">
                                        <svg className="header-popup-item__icon" width="24" height="26"
                                            viewBox="0 0 24 26"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.1149 24.5H4.66076C2.78487 24.5 1.26416 22.8211 1.26416 20.75V7M6.92515 19.5H19.3793C21.2552 19.5 22.7759 17.8211 22.7759 15.75V4.5C22.7759 2.42893 21.2552 0.75 19.3793 0.75H9.18955C7.31366 0.75 5.79296 2.42893 5.79296 4.5V18.25C5.79296 18.9404 6.29986 19.5 6.92515 19.5Z"
                                                stroke="#731975" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                        </svg>
                                        <div className="header-popup-item-body">
                                            <p className="header-popup-item__title">Анкета</p>
                                            <span className="header-popup-item__text">анкета кандидата при приеме на работу успешно заполнена и отправлена.</span>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>)}
                        </AnimatePresence>

                    </div>

                    <div className="header__company">
                        <img className="header__company-image" src="/company-placeholder.png" alt="" />
                        <div className="header__company-body">
                            <p className="header__company-name">
                                Компания
                            </p>
                            <p className="header__company-direction">
                                Направление
                            </p>
                        </div>
                    </div>

                </div>
            </div>

        </header>
    );
};

export default Header;