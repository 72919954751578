// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentors-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mentors-page__title {
  font-weight: 500;
  font-size: 70px;
  background: -webkit-linear-gradient(20deg, #731975 0%, #FFFFFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
}
.mentors-page__text {
  color: rgb(235, 249, 251);
  margin-bottom: 32px;
  max-width: 974px;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/routes/Mentors/MentorsPage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EAEA,oEAAA;EAEA,6BAAA;EACA,oCAAA;EACA,mBAAA;AADJ;AAIE;EACE,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAFJ","sourcesContent":[".mentors-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__title {\n    font-weight: 500;\n    font-size: 70px;\n\n    background: -webkit-linear-gradient(20deg, #731975 0%, #FFFFFF 100%);\n\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    margin-bottom: 16px;\n  }\n\n  &__text {\n    color: rgba(235, 249, 251, 1);\n    margin-bottom: 32px;\n    max-width: 974px;\n    font-weight: 400;\n    font-size: 22px;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
