import {FC} from 'react';
import './FloatingRules.scss';

export interface IFloatingRulesProps {
    className?: string;
    color: 'orange' | 'purple' | 'pink' | 'lightpurple';
    text: string;
}

export const FloatingRule: FC<IFloatingRulesProps> = ({text, className = '', color}) => {
    return (
        <div className={`${className} floating-rule ${color}`}>
            <svg width="201" height="69" viewBox="0 0 201 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_294_2382)">
                    <path
                        d="M163.652 4.4307C162.149 3.81612 160.542 3.5 158.919 3.5H23C16.0964 3.5 10.5 9.09644 10.5 16V39C10.5 45.9036 16.0964 51.5 23 51.5H158.919C160.542 51.5 162.149 51.1839 163.652 50.5693L182.733 42.7631C187.431 40.8413 190.5 36.2695 190.5 31.1938V23.8062C190.5 18.7305 187.431 14.1587 182.733 12.2368L163.652 4.4307Z"
                        stroke="currentColor"  shapeRendering="crispEdges"/>
                </g>
            </svg>

            <div className="floating-rule-image-wrapper">
                <svg width="46" height="49" viewBox="0 0 46 49" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M28.6236 1.98025C24.9108 -0.16334 20.3365 -0.16334 16.6236 1.98025L6 8.11382C2.28719 10.2574 0 14.2189 0 18.5061V30.7733C0 35.0604 2.28719 39.022 6 41.1656L16.6236 47.2991C20.3365 49.4427 24.9108 49.4427 28.6236 47.2991L39.2473 41.1656C42.9601 39.022 45.2473 35.0604 45.2473 30.7733V18.5061C45.2473 14.2189 42.9601 10.2574 39.2473 8.11382L28.6236 1.98025Z"
                          fill="currentColor"/>
                </svg>
                <img className="floating-rule-image" src={`/rule-${color}.png`} alt=""/>
            </div>
            <small className="floating-rule-text">{text}</small>
        </div>
    );
};
