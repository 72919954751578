// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes transform-bounce {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes transform-max-width {
  0% {
    width: 0;
  }
}
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 33px;
  border: 1px solid rgb(229, 231, 235);
  color: rgb(115, 25, 117);
}
.pagination-button:disabled {
  color: rgb(229, 231, 235);
}
.pagination-button:first-of-type {
  border-radius: 5px 0 0 5px;
}
.pagination-button:not(:last-of-type) {
  border-right: 0;
}
.pagination-button:last-of-type {
  border-radius: 0 5px 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.scss","webpack://./src/components/Pagination/Pagination.scss"],"names":[],"mappings":"AASA;EACE;IACE,mBAAA;ECRF;EDUA;IACE,sBAAA;ECRF;EDUA;IACE,mBAAA;ECRF;EDUA;IACE,sBAAA;ECRF;EDUA;IACE,mBAAA;ECRF;AACF;ADYA;EACE;IACE,QAAA;ECVF;AACF;AAnBA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AAqBF;AAnBE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,YAAA;EAEA,oCAAA;EAEA,wBDfK;ACkCT;AAlBI;EACE,yBAAA;AAoBN;AAlBI;EACE,0BAAA;AAoBN;AAjBI;EACE,eAAA;AAmBN;AAhBI;EACE,0BAAA;AAkBN","sourcesContent":["$darkblue-1: #2C2F48;\n\n$purple: rgba(115, 25, 117, 1);\n$red: rgba(224, 36, 36, 1);\n$grey: rgba(107, 114, 128, 1);\n$light-grey: rgba(181, 183, 192, 1);\n$light-purple: rgba(242, 239, 255, 1);\n$pre-black: rgba(49, 49, 49, 1);\n\n@keyframes transform-bounce {\n  0%{\n    transform: scale(0);\n  }\n  25%{\n    transform: scale(1.25);\n  }\n  50%{\n    transform: scale(1);\n  }\n  75%{\n    transform: scale(1.25);\n  }\n  100%{\n    transform: scale(1);\n  }\n}\n\n\n@keyframes transform-max-width {\n  0%{\n    width: 0;\n  }\n}\n","@import 'src/assets/styles/variables';\n\n.pagination {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n\n  &-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0 12px;\n    height: 33px;\n\n    border: 1px solid rgba(229, 231, 235, 1);\n\n    color: $purple;\n    &:disabled{\n      color:  rgba(229, 231, 235, 1);\n    }\n    &:first-of-type {\n      border-radius: 5px 0 0 5px;\n    }\n\n    &:not(:last-of-type ) {\n      border-right: 0;\n    }\n\n    &:last-of-type {\n      border-radius: 0 5px 5px 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
